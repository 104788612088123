import React, { useState } from 'react';
import { Input, Button, message } from 'antd';
import { API } from 'src/api/API';
import moment from 'moment';
import { useMe } from 'src/hooks/users/useMe';
import { useTranslation } from 'react-i18next';

interface Comment {
    text: string;
    timestamp: string;
    userName: string;
}

interface CommentListProps {
    objectId: number;
    comments: Comment[];
    relatedToId: string;
    mutate: (objectId: number) => void;
}

const CommentListSensor: React.FC<CommentListProps> = ({
    objectId,
    comments,
    relatedToId,
    mutate,
}) => {
    const [newComment, setNewComment] = useState<string>('');
    const [isViewAll, setIsViewAll] = useState<boolean>(false);
    const { t } = useTranslation();
    const { data: me } = useMe();

    const handleViewAll = () => {
        setIsViewAll(true);
    };

    const handleViewLess = () => {
        setIsViewAll(false);
    };

    const handleAddComment = async () => {
        if (!newComment.trim()) {
            message.warning('Please enter a comment!');
            return;
        }

        try {
            const comment = {
                text: newComment,
                userId: me?.user.id,
                relatedTo: 'Sensor',
                relatedToId,
            };

            await new API().createComment(objectId, comment);

            setNewComment('');
            message.success('Comment added successfully!');

            mutate(objectId);
        } catch (error) {
            message.error('Failed to add comment');
        }
    };

    const displayedComments = isViewAll ? comments : comments.slice(0, 2);

    return (
        <>
            {displayedComments?.map((comment, index) => {
                const date = moment(comment.timestamp);
                const formattedDate = `${date.year()}-${date.format(
                    'MM',
                )}-${date.format('DD')} ${date.format('HH:mm')}`;
                const formattedText = comment.text.replace(
                    /(\r\n|\n|\r)/gm,
                    '<br />',
                );

                return (
                    <>
                        <div key={index} style={{ margin: 10 }}>
                            <strong>{formattedDate}</strong>
                            {', '}
                            <strong>{comment.userName}:</strong>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: formattedText,
                                }}
                            />
                        </div>
                    </>
                );
            })}
            {comments.length > 2 && !isViewAll && (
                <div
                    className="flex justify-start"
                    style={{ marginTop: 10, marginBottom: 10 }}
                >
                    <Button type="text" onClick={handleViewAll}>
                        {t('View all')}
                    </Button>
                </div>
            )}
            <hr />
            {!!comments.length && isViewAll && (
                <div
                    className="flex justify-start"
                    style={{ marginTop: 10, marginBottom: 10 }}
                >
                    <Button type="text" onClick={handleViewLess}>
                        {t('View less')}
                    </Button>
                </div>
            )}
            <div className="flex" style={{ margin: 10 }}>
                <Input.TextArea
                    value={newComment}
                    onChange={(e) => setNewComment(e.target.value)}
                    rows={1}
                    placeholder="Add a comment"
                />
                <Button
                    type="primary"
                    style={{ marginLeft: 10 }}
                    onClick={handleAddComment}
                >
                    Lägg till
                </Button>
            </div>
        </>
    );
};

export default CommentListSensor;
