import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/store/rootReducer';

export type ConfigState = {
    config: any;
    loading: 'idle' | 'pending';
    error: any;
    data: any[];
};

const initialState: ConfigState = {
    config: null,
    loading: 'idle',
    error: null,
    data: [],
};

export const modulatorConfigSlice = createSlice({
    name: 'modulatorConfig',
    initialState,
    reducers: {
        setModulatorConfigProperties: (state, action: PayloadAction<any>) => {
            state.config = { ...action.payload };
        },
        setModulatorConfigData: (state, action: PayloadAction<any[]>) => {
            state.data = [...action.payload];
        },
    },
});

export const selectConfig = (state: RootState) => state.modulatorConfig;

// Slice action creators
export const {
    setModulatorConfigProperties,
    setModulatorConfigData,
} = modulatorConfigSlice.actions;

export default modulatorConfigSlice.reducer;
