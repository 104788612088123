//import { useRealTimeFetch } from 'src/hooks/objects/useRealTimeFetch';
import { Col, Row, Skeleton, Card, Tabs, Collapse } from 'antd';
import { useState, useEffect, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DataSelector } from 'src/components/DataSelector';
import { RootState } from 'src/store/rootReducer';
import { useSelector, useDispatch } from 'react-redux';
import RealtimeTable from 'src/views/objects/RealtimeTable';
import useSensorAverage from 'src/hooks/objects/useSensorAverageWithCache';
import useViewport from 'src/hooks/generic/useViewport';
import use3DRealtimeTemperatures from 'src/components/House3D/hooks/use3DRealtimeTemperatures';
import useAvgTempObjRealTime from 'src/hooks/objects/useAvgTempObjRealTime';
import useFetchHotColdRealtime from 'src/components/House3D/hooks/useFetchHotColdRealtime';
import use3DData from 'src/components/House3D/hooks/use3DData';
import GraphSliderRealtime from 'src/views/objects/Balancing/GraphSliderRealtime';
import { selectRealtimeVisualization } from 'src/store/reducers/realTimeVisualization';
import { addToExistingRealtimeSensors } from 'src/store/reducers/selectedRealtimeSensorsSlice';
import { setStartDate, setStopDate } from 'src/store/reducers/dateSlice';
import { RealTimeGraphHeating } from 'src/components/RealTimeGraphHeating';
import RangePickerRealTime from 'src/components/RangePickerRealTime';
import { useGetDOT } from 'src/hooks/objects/useGetDOT';
import { ControlState } from 'src/models/ControlState';
import { useAverageTemperaturesWithControlState } from 'src/hooks/objects/useAverageTemperaturesWithControllState';
import { useTemperatures } from 'src/hooks/objects/useTemperatures';
import './RealTimeView.scss';
import {
    useRealTimeHeating,
    RealTimeResponse,
} from 'src/hooks/objects/useRealTimeHeating';
import {
    generateGradientKey,
    //getGradientColorForTemperature,
    gradients,
} from 'src/utils/gradients';
import BalancingVisualization from 'src/views/objects/Balancing/BalancingVisualization';
import { clearSelectedKeys } from 'src/store/reducers/selectedKeysSlice';
import { setSelectedRealtimeSensors } from 'src/store/reducers/selectedRealtimeSensorsSlice';
import {
    clearSelectedFloors,
    setAvgData,
    setPeriod,
} from 'src/store/reducers/selectedObjectSlice';
import { useAvgTempFloorRealTime } from 'src/hooks/objects/useAvgTempFloorRealTime';
import useObjectTree from 'src/hooks/tree/useObjectTree';
import CollapsibleCommentsView from './CollapsibleCommentsView';

interface Props {
    objectId: number;
    controlStates: ControlState[];
    setActiveTabIndex?: (index: number) => void;
}

function RealTimeView(props: Props) {
    const { objectId, controlStates } = props;

    const [isHotColdActive, setHotColdActive] = useState(false);
    //const [start, setStart] = useState(period.start);
    //const [stop, setStop] = useState(period.stop);
    const start = useSelector((state: RootState) => state.date.start);
    const stop = useSelector((state: RootState) => state.date.stop);

    const { Panel } = Collapse;

    const { data: sensorAveragesList } = useTemperatures(
        objectId,
        controlStates[controlStates.length - 1].state_order,
    );
    const [sensorAveragesHeating, prevSensorAverages] = sensorAveragesList ?? [
        undefined,
        undefined,
    ];
    const {
        data: averageTemperatures,
        previous: previousAverageTemperatures,
    } = useAverageTemperaturesWithControlState(
        objectId,
        controlStates[controlStates.length - 1].state_order,
    );
    const { data: DOT } = useGetDOT(
        objectId,
        controlStates[controlStates.length - 1].state_order,
    );

    const selectedSensors = useSelector(
        (state: RootState) => state.selectedSensors,
    );

    //eslint-disable-next-line
    const { mode, screen } = useViewport();
    const { t } = useTranslation();
    const {
        isLoading: isLoadingHeating,
        data: dataHeating,
        hotColdFlag: hotColdFlagHeating,
    } = useRealTimeHeating(props.objectId, start, stop, 0, 21, 11, 1, 51, 61);

    const dispatch = useDispatch();
    const realtimeTableRef = useRef<{ clearData: () => void }>(null);
    const commentListRef = useRef<{ scrollToAddComment: () => void }>(null);

    const [refAreaLeft, setRefAreaLeft] = useState<number>(0);
    const [refAreaRight, setRefAreaRight] = useState<number>(0);
    const [left, setLeft] = useState<number | string>('dataMin');
    const [right, setRight] = useState<number | string>('dataMax');
    const [include, setInclude] = useState<number[]>([]);
    const [heatingInclude, setHeatingInclude] = useState<number[]>([]);
    const [timestamps, setTimeStamps] = useState<number[]>([]);
    //const [partition] = usePartitionRealTime();
    const { nodeType, avgData, selectedFloorSensors } = useSelector(
        (state: RootState) => state.selectedObject,
    );

    const [tableView, setTableView] = useState<'graph' | 'table'>('graph');
    //eslint-disable-next-line
    const [selectedOptions, setSelectedOptions] = useState(['REALTIME']);
    const { interval } = useSelector(selectRealtimeVisualization);

    const [fetchAvgTemp] = useAvgTempFloorRealTime();
    const isMounted = useRef(false);

    const {
        data: houseData,
        error,
        isLoading: isLoadingVisualization,
    } = use3DData(
        objectId,
        controlStates[controlStates.length - 1].state_order,
    );

    const { data: objectTree } = useObjectTree(objectId);

    const {
        isLoading: isLoadingRealtimeTemperatures,
        data: realtimeRawTemperatures,
    } = use3DRealtimeTemperatures(objectId, start, stop);

    const { isLoading, data: avgObjData } = useAvgTempObjRealTime(
        objectId,
        start,
        stop,
    );

    const { fetchHotNCold } = useFetchHotColdRealtime();
    const [referenceTemperature, setReferenceTemperature] = useState(NaN);

    // eslint-disable-next-line
    const [threshold, setTreshold] = useState(1);
    // eslint-disable-next-line
    const [humidityLevel, setHumidityLevel] = useState(0.2);
    const {
        //eslint-disable-next-line
        sensors: sensorAverages,
        selectedRealtimeSensors: sensorIds,
    } = useSensorAverage(
        objectId,
        controlStates[controlStates.length - 1].state_order,
    );

    const clearSensorsOnly = () => {
        setInclude([]);
        setHeatingInclude([]);
        // setHotColdFlag(false);

        // setData({
        //     ...data,
        //     data1: [],
        //     data2: [],
        // });
    };

    const clearAllSensors = async () => {
        clearSensorsOnly();
        setHotColdActive(false);
        await dispatch(clearSelectedKeys());
        await dispatch(clearSelectedFloors());
        await dispatch(setSelectedRealtimeSensors([]));

        if (realtimeTableRef.current) {
            realtimeTableRef.current.clearData();
        }
    };

    const fetchHotAndColdAgain = async () => {
        fetchHotNCold(objectId, start, stop, true);
    };

    function removeDuplicatesById(
        nestedArray: RealTimeResponse[][],
    ): RealTimeResponse[][] {
        // Flatten the array and remove duplicates based on `id`
        const uniqueItems = Array.from(
            new Map(
                nestedArray
                    .flat() // Flatten the nested arrays
                    .map((item) => [item.id, item]), // Map each item by its `id`
            ).values(), // Get the unique items
        );

        // Regroup the unique items into nested arrays, if necessary
        const regroupedArray: RealTimeResponse[][] = [[]];

        uniqueItems.forEach((item) => {
            regroupedArray[0].push(item);
        });

        return regroupedArray;
    }

    // useEffect(() => {
    //     // fetchData(
    //     //     objectId,
    //     //     start,
    //     //     stop,
    //     //     sensorType.type1,
    //     //     sensorType.type2,
    //     //     sensorType.type3,
    //     // );
    //     dispatch(setRefreshFlag(false));
    //     setHotColdActive(true);
    //     // eslint-disable-next-line
    // }, [refreshFlag]);

    useEffect(() => {
        // const selectedSensorsByObjectId = selectedSensors[objectId] || [];
        // if (selectedSensorsByObjectId && selectedSensorsByObjectId.length) {
        //     //setPartionLoading(true);

        //     const sensorIds = selectedSensorsByObjectId.map(
        //         (sensorId) => sensorId,
        //     );

        //     // Perform the partition operation when selectedSensors change
        //     // eslint-disable-next-line
        //     partition(objectId, sensorIds, start, stop).then((v: any) => {
        //         //setPartionLoading(false);
        //         setInclude([]);
        //         setHeatingInclude([]);
        //         setHotColdFlag(false);
        //         if (v && v.length) {
        //             if (v[1].length) {
        //                 setData({
        //                     ...data,
        //                     data1: v[0],
        //                     data2: v[1],
        //                 });
        //             } else {
        //                 setData({
        //                     ...data,
        //                     data1: v[0],
        //                 });
        //             }
        //             setSearchActive(true);
        //         }
        //     });
        // } else
        if (isHotColdActive) {
            // fetchData(
            //     objectId,
            //     start,
            //     stop,
            //     sensorType.type1,
            //     sensorType.type2,
            //     sensorType.type3,
            // );
            console.log('need to add statement here ');
        } else {
            clearSensorsOnly();
        }

        if (isHotColdActive && nodeType?.type === 'sensor') {
            setHotColdActive(false);
        }
        // eslint-disable-next-line
    }, [objectId, selectedSensors, start, stop]);

    useEffect(() => {
        if (
            start &&
            stop &&
            selectedFloorSensors &&
            selectedFloorSensors.length
        ) {
            if (selectedFloorSensors && selectedFloorSensors.length) {
                fetchAvgTemp(objectId, start, stop, selectedFloorSensors).then(
                    (result) => {
                        dispatch(setAvgData(result[0]));
                    },
                );
            }
        } else {
            dispatch(setAvgData([]));
        }
    }, [objectId, start, stop, selectedFloorSensors]); //eslint-disable-line

    useEffect(() => {
        if (!isMounted.current && start && stop) {
            fetchHotNCold(objectId, start, stop, false);
            isMounted.current = true;
        }
    }, [objectId, start, stop]); //eslint-disable-line

    const generateTicks = (start: Date, end: Date, interval: number) => {
        start.setHours(0, 0, 0, 0);

        const firstTimestampStartOfDay = start.getTime() / 1000;

        end.setHours(0, 0, 0, 0);
        end.setDate(end.getDate() + 1);

        const lastTimestamp = end.getTime() / 1000;

        const intervalInSeconds = interval * 3600;

        let ticks: number[] = [];

        if (mode === 'mobile') {
            // Ensure only 4 ticks: start, two in between, and end
            ticks = [firstTimestampStartOfDay];

            const midInterval = (lastTimestamp - firstTimestampStartOfDay) / 3;

            ticks.push(firstTimestampStartOfDay + midInterval);
            ticks.push(firstTimestampStartOfDay + 2 * midInterval);

            ticks.push(lastTimestamp);
        } else {
            // Default mode - Generate ticks based on the real interval
            for (
                let timestamp = firstTimestampStartOfDay;
                timestamp <= lastTimestamp;
                timestamp += intervalInSeconds //use real interval
            ) {
                ticks.push(timestamp);
            }

            if (ticks[ticks.length - 1] !== lastTimestamp) {
                ticks.push(lastTimestamp);
                ticks.splice(-2, 1); // Ensure no duplicate of second last tick
            }
        }

        return ticks;
    };

    useEffect(() => {
        const timestamps = generateTicks(
            new Date(start!),
            new Date(stop!),
            interval,
        );
        setTimeStamps(timestamps);

        // isMounted.current
        //     ? fetchAvgTempObj(objectId, start, stop).then((result) => {
        //           setData((prevData) => ({
        //               ...prevData,
        //               avgObjData: result[0],
        //           }));
        //       })
        //     : (isMounted.current = true);
    }, [start, stop, interval]); // eslint-disable-line

    const avg =
        0.1 *
        Math.round(
            10 *
                (averageTemperatures?.data.find(
                    (e: any) => e.fromTemperature === referenceTemperature,
                )?.toTemperature ?? 21),
        );

    const getGradientKey = () => {
        if (houseData) {
            return generateGradientKey(
                {
                    high: houseData?.thresholds.high,
                    low: houseData?.thresholds.low,
                },
                gradients.thermometer.mix,
            );
        }

        return generateGradientKey(
            { high: avg + threshold, low: avg - threshold },
            gradients.thermometer.mix,
        );
    };

    const memoizedData = useMemo(() => realtimeRawTemperatures, [
        realtimeRawTemperatures,
    ]);
    const memoizedAvgObjData = useMemo(() => avgObjData, [avgObjData]);
    const memoizedAvgData = useMemo(() => avgData, [avgData]);
    const memoizedStart = useMemo(() => start, [start]);
    const memoizedHigh = useMemo(() => houseData?.thresholds.high || 22, [
        houseData?.thresholds.high,
    ]);
    const memoizedLow = useMemo(() => houseData?.thresholds.low || 20, [
        houseData?.thresholds.low,
    ]);
    const memoizedNewTimestamps = useMemo(() => timestamps, [timestamps]);

    const array = [
        dataHeating.data1,
        dataHeating.data2,
        dataHeating.data3,
        dataHeating.data4,
        dataHeating.data5,
        dataHeating.data6,
    ];

    const aggregatedResult = removeDuplicatesById(array);

    return (
        <>
            <Row gutter={[20, 20]}>
                <Col xs={24} md={24}>
                    <RangePickerRealTime
                        period={{ start: start, stop: stop }}
                        isHotColdActive={isHotColdActive}
                        onChange={({ start, stop }) => {
                            dispatch(setStartDate(start));
                            dispatch(setStopDate(stop));
                            dispatch(setPeriod({ start, stop }));
                        }}
                        clearSensors={clearAllSensors}
                        fetchHotAndColdAgain={fetchHotAndColdAgain}
                        setHotAndColdState={setHotColdActive}
                    />
                </Col>
            </Row>

            <Row gutter={[20, 20]}>
                <Col xs={24} md={24}>
                    <div className="flex w-full justify-center items-center flex-wrap flex-col">
                        <div
                            className="grid flex-1 no-padding-tab"
                            style={{
                                gridTemplateColumns:
                                    mode === 'desktop'
                                        ? 'minmax(110ch, 55vw) 1fr'
                                        : '1fr',
                                padding: 0,
                                width: mode === 'mobile' ? '100%' : 'auto',
                            }}
                        >
                            <Card className="h-full">
                                <Tabs
                                    onChange={(value) => {
                                        setTableView(
                                            value as 'table' | 'graph',
                                        );
                                    }}
                                    activeKey={tableView}
                                    type="card"
                                    className=""
                                >
                                    <Tabs.TabPane
                                        key="graph"
                                        className="h-full"
                                        tab={t('3D')}
                                        style={{ padding: 0 }}
                                    >
                                        {controlStates && (
                                            <BalancingVisualization
                                                controlStateId={
                                                    controlStates[
                                                        controlStates.length - 1
                                                    ].state_order
                                                }
                                                objectId={objectId}
                                                referenceTemperature={
                                                    DOT
                                                        ? DOT.pseudoDOT ??
                                                          Math.round(DOT.dot)
                                                        : NaN
                                                }
                                                sensorIds={sensorIds}
                                                setReferenceTemperature={
                                                    setReferenceTemperature
                                                }
                                                setSensorIds={(sensorIds) =>
                                                    dispatch(
                                                        addToExistingRealtimeSensors(
                                                            sensorIds,
                                                        ),
                                                    )
                                                }
                                                setTableView={setTableView}
                                                transformedData={[]}
                                                threshold={threshold}
                                                gradientKey={getGradientKey()}
                                                humidityData={[]}
                                                humidityLevel={humidityLevel}
                                                viewMode={selectedOptions}
                                                data={houseData}
                                                error={error}
                                            />
                                        )}
                                    </Tabs.TabPane>
                                    <Tabs.TabPane
                                        className="h-full overflow-hidden"
                                        key="table"
                                        tab={t('Table')}
                                    >
                                        {realtimeRawTemperatures &&
                                            !!realtimeRawTemperatures.length &&
                                            objectTree &&
                                            !!objectTree.length && (
                                                <RealtimeTable
                                                    ref={realtimeTableRef}
                                                    realtimeRawTemperatures={
                                                        realtimeRawTemperatures
                                                    }
                                                    objectTree={objectTree}
                                                    objectId={objectId}
                                                />
                                            )}
                                    </Tabs.TabPane>
                                </Tabs>
                            </Card>
                        </div>
                    </div>
                </Col>
            </Row>
            <CollapsibleCommentsView
                objectId={objectId}
                realtimeRawTemperatures={realtimeRawTemperatures}
                commentListRef={commentListRef}
            />

            <Collapse
                defaultActiveKey={[]}
                className="collapse-realtime"
                bordered={false}
                style={{ background: '#fff' }}
            >
                <Panel
                    header={<div style={{ fontSize: '20px' }}>Grafer</div>}
                    key="1"
                >
                    <Row gutter={[20, 20]}>
                        <Col xs={24} md={24}>
                            {realtimeRawTemperatures &&
                                !isLoading &&
                                !isLoadingVisualization &&
                                !isLoadingRealtimeTemperatures &&
                                avgObjData &&
                                !!avgObjData.length && (
                                    <div className="flex w-full justify-center items-center flex-wrap flex-col">
                                        <div
                                            className="grid flex-1"
                                            style={{
                                                gridTemplateColumns:
                                                    mode === 'desktop'
                                                        ? 'minmax(110ch, 55vw) 1fr'
                                                        : '1fr',
                                                width:
                                                    mode === 'mobile'
                                                        ? '100%'
                                                        : 'auto',
                                            }}
                                        >
                                            <GraphSliderRealtime
                                                data={memoizedData}
                                                avgData={memoizedAvgData}
                                                avgObjData={memoizedAvgObjData}
                                                start={memoizedStart}
                                                high={memoizedHigh}
                                                low={memoizedLow}
                                                newTimestamps={
                                                    memoizedNewTimestamps
                                                }
                                            />
                                        </div>
                                    </div>
                                )}
                            {(isLoading ||
                                isLoadingVisualization ||
                                isLoadingRealtimeTemperatures) && (
                                <div className="flex w-full justify-center items-center flex-wrap flex-col">
                                    <div
                                        className="grid flex-1"
                                        style={{
                                            gridTemplateColumns:
                                                mode === 'desktop'
                                                    ? 'minmax(110ch, 55vw) 1fr'
                                                    : '1fr',
                                            width:
                                                mode === 'mobile'
                                                    ? '100%'
                                                    : 'auto',
                                        }}
                                    >
                                        <Skeleton />
                                    </div>
                                </div>
                            )}
                        </Col>
                    </Row>

                    <Row gutter={[20, 20]}>
                        <Col xs={24} md={24}>
                            {!isLoadingHeating && (
                                <div className="flex w-full justify-center items-center flex-wrap flex-col">
                                    <div
                                        className="grid flex-1"
                                        style={{
                                            gridTemplateColumns:
                                                mode === 'desktop'
                                                    ? 'minmax(110ch, 55vw) 1fr'
                                                    : '1fr',
                                            width:
                                                mode === 'mobile'
                                                    ? '100%'
                                                    : 'auto',
                                        }}
                                    >
                                        <RealTimeGraphHeating
                                            newTimestamps={timestamps}
                                            hotNCold={hotColdFlagHeating}
                                            refLeft={refAreaLeft}
                                            refRight={refAreaRight}
                                            start={start}
                                            stop={stop}
                                            left={left}
                                            right={right}
                                            onMouseDown={(e: any) => {
                                                setRefAreaLeft(e.activeLabel);
                                            }}
                                            onMouseMove={(e: any) => {
                                                refAreaLeft &&
                                                    setRefAreaRight(
                                                        e.activeLabel,
                                                    );
                                            }}
                                            onReset={() => {
                                                setLeft('dataMin');
                                                setRight('dataMax');
                                            }}
                                            zoom={() => {
                                                if (
                                                    refAreaLeft ===
                                                        refAreaRight ||
                                                    refAreaRight === 0
                                                ) {
                                                    setRefAreaLeft(0);
                                                    setRefAreaRight(0);
                                                    return;
                                                }
                                                if (
                                                    refAreaLeft > refAreaRight
                                                ) {
                                                    setRight(refAreaLeft);
                                                    setLeft(refAreaRight);
                                                } else {
                                                    setLeft(refAreaLeft);
                                                    setRight(refAreaRight);
                                                }
                                                setRefAreaLeft(0);
                                                setRefAreaRight(0);
                                            }}
                                            include={heatingInclude}
                                            data={dataHeating.data1}
                                            data2={dataHeating.data2}
                                            data3={dataHeating.data3}
                                            data4={dataHeating.data4}
                                            data5={dataHeating.data5}
                                            data6={dataHeating.data6}
                                            firstAxisColor={'#91d5ff'}
                                            secondAxisColor={'#fa541c'}
                                            sensorAverages={
                                                sensorAveragesHeating ?? []
                                            }
                                            prevSensorAverages={
                                                prevSensorAverages
                                            }
                                            averageTemperatures={
                                                averageTemperatures?.data ?? []
                                            }
                                            prevAverageTemperatures={
                                                previousAverageTemperatures?.data ??
                                                []
                                            }
                                            referenceTemperature={
                                                DOT
                                                    ? DOT.pseudoDOT ??
                                                      Math.round(DOT.dot)
                                                    : NaN
                                            }
                                        />
                                    </div>
                                </div>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} md={24}>
                            <div className="flex w-full justify-center items-center flex-wrap flex-col">
                                <div
                                    className="grid flex-1"
                                    style={{
                                        gridTemplateColumns:
                                            mode === 'desktop'
                                                ? 'minmax(110ch, 55vw) 1fr'
                                                : '1fr',
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '100%',
                                            flexWrap: 'wrap',
                                        }}
                                    >
                                        {!isLoadingHeating &&
                                            aggregatedResult.map(
                                                (data, index) =>
                                                    data && (
                                                        <DataSelector
                                                            key={index}
                                                            hotNCold={false}
                                                            data={data}
                                                            onClick={(val) => {
                                                                if (
                                                                    !heatingInclude.includes(
                                                                        val,
                                                                    )
                                                                ) {
                                                                    setHeatingInclude(
                                                                        [
                                                                            ...include,
                                                                            val,
                                                                        ],
                                                                    );
                                                                } else {
                                                                    const temp = include.filter(
                                                                        (v) =>
                                                                            v !==
                                                                            val,
                                                                    );
                                                                    setHeatingInclude(
                                                                        temp,
                                                                    );
                                                                }
                                                            }}
                                                            include={
                                                                heatingInclude
                                                            }
                                                        />
                                                    ),
                                            )}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Panel>
            </Collapse>
        </>
    );
}

export default RealTimeView;
