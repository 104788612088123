import React, { useState, useMemo } from 'react';
import { Button } from 'antd';
import moment from 'moment';
import { Warning } from 'src/models/WarningDiagnostic';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { RootState } from 'src/store/rootReducer';

interface WarningListProps {
    objectId: number;
    relatedToId?: number;
    isConfigView?: boolean;
}

const MAX_HEIGHT = 88;

const WarningList = (props: WarningListProps) => {
    //eslint-disable-next-line
    const { objectId, relatedToId, isConfigView } = props;
    const allWarnings = useSelector(
        (state: RootState) => state.warnings.warnings,
    );
    //eslint-disable-next-line
    const warnings: Warning[] =
        allWarnings?.filter((warn) => warn.objectId === objectId) || [];

    const [isViewAll, setIsViewAll] = useState<boolean>(false);
    const { t } = useTranslation();

    const warningsToRender = relatedToId
        ? warnings?.filter((comment) => comment.sensorId === relatedToId) || []
        : warnings;

    const handleViewAll = () => {
        setIsViewAll(true);
    };

    const handleViewLess = () => {
        setIsViewAll(false);
    };

    const { limitedWarnings, totalHeight } = useMemo(() => {
        if (!warnings || !warnings.length || relatedToId) {
            return { limitedWarnings: [], totalHeight: 0 };
        }

        let totalHeight = 0;
        let allHeight = 0;
        const limitedWarnings: any[] = [];
        for (let index = 0; index < warnings.length; index++) {
            const comment = warnings[index];

            const date = moment(comment.validFrom);
            const formattedDate = `${date.year()}-${date.format(
                'MM',
            )}-${date.format('DD')} ${date.format('HH:mm')}`;
            const measuredDate = moment(comment.latestMeasurementTimestamp);
            const measuredFormattedDate = `${measuredDate.year()}-${measuredDate.format(
                'MM',
            )}-${measuredDate.format('DD')} ${measuredDate.format('HH:mm')}`;
            const formattedText = `Senast sensorn sågs vid liv var ${measuredFormattedDate}, då mottogs värdet ${comment.latestMeasurementValue?.toFixed(
                2,
            )}`;

            const tempDiv = document.createElement('div');
            tempDiv.style.position = 'absolute';
            tempDiv.style.margin = '10px';
            tempDiv.style.visibility = 'hidden';
            tempDiv.style.height = 'auto';
            tempDiv.innerHTML = `
                <strong>${formattedDate}</strong>${' '}
                <strong>${comment.sensorName} - </strong>
                <strong>${comment.sensorName} - </strong>
                <strong>Ingen kommunikation</strong>
                <div>#${formattedText}</div>
            `;
            document.body.appendChild(tempDiv);
            const height = tempDiv.offsetHeight;
            document.body.removeChild(tempDiv);

            allHeight = allHeight + height;

            if (totalHeight + height <= MAX_HEIGHT) {
                limitedWarnings.push(comment);
                totalHeight += height;
            } else if (limitedWarnings.length === 0 && height > MAX_HEIGHT) {
                limitedWarnings.push(comment);
                break;
            } else {
                break;
            }
        }

        return { limitedWarnings, totalHeight: allHeight };
    }, [warnings, relatedToId]);

    const displayedWarnings =
        isViewAll || relatedToId ? warningsToRender : limitedWarnings;

    return (
        <>
            <div
                className="overflow-auto"
                style={{
                    maxHeight: `${
                        !relatedToId && !isViewAll && totalHeight > MAX_HEIGHT
                            ? '120px'
                            : '24rem'
                    }`,
                }}
            >
                {displayedWarnings?.map((warning, index) => {
                    const date = moment(warning.validFrom);
                    const formattedDate = `${date.year()}-${date.format(
                        'MM',
                    )}-${date.format('DD')} ${date.format('HH:mm')}`;
                    const measuredDate = moment(
                        warning.latestMeasurementTimestamp,
                    );
                    const measuredFormattedDate = `${measuredDate.year()}-${measuredDate.format(
                        'MM',
                    )}-${measuredDate.format('DD')} ${measuredDate.format(
                        'HH:mm',
                    )}`;
                    const formattedText = `Senast sensorn sågs vid liv var <strong>${measuredFormattedDate}</strong>, då mottogs värdet 
                    <strong>${warning.latestMeasurementValue?.toFixed(
                        1,
                    )}</strong>
                    `;
                    return (
                        <>
                            <div
                                key={index}
                                style={{
                                    margin: 10,
                                    color: 'red',
                                    marginBottom: `${
                                        index === displayedWarnings.length - 1
                                            ? '0px'
                                            : '10px'
                                    }`,
                                }}
                            >
                                {isConfigView ? (
                                    <>
                                        <strong>{warning.sensorName}</strong>{' '}
                                        <strong>{formattedDate} - </strong>
                                        <strong>Ingen kommunikation</strong>
                                    </>
                                ) : (
                                    <>
                                        <strong>{formattedDate}</strong>{' '}
                                        <strong>{warning.sensorName} - </strong>
                                        <strong>Ingen kommunikation</strong>
                                    </>
                                )}
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: formattedText,
                                    }}
                                />
                            </div>
                        </>
                    );
                })}
            </div>
            {warningsToRender &&
                totalHeight > MAX_HEIGHT &&
                !isViewAll &&
                !relatedToId && (
                    <div className="flex justify-start">
                        <Button type="text" onClick={handleViewAll}>
                            {t('View all')}
                        </Button>
                    </div>
                )}
            {!!warningsToRender && isViewAll && !relatedToId && (
                <div className="flex justify-start">
                    <Button type="text" onClick={handleViewLess}>
                        {t('View less')}
                    </Button>
                </div>
            )}
        </>
    );
};

export default WarningList;
