// features/warnings/warningsSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Warning {
    id: number;
    objectId: number;
    sensorName: string;
    validFrom: string;
    latestMeasurementTimestamp: string;
    latestMeasurementValue: number;
    sensorId: number;
    type: string;
}

interface WarningsState {
    warnings: Warning[];
}

const initialState: WarningsState = {
    warnings: [],
};

const warningsSlice = createSlice({
    name: 'warnings',
    initialState,
    reducers: {
        setWarnings: (state, action: PayloadAction<Warning[]>) => {
            state.warnings = action.payload;
        },
        addWarning: (state, action: PayloadAction<Warning>) => {
            state.warnings.push(action.payload);
        },
        removeWarning: (state, action: PayloadAction<number>) => {
            state.warnings = state.warnings.filter(
                (warning) => warning.id !== action.payload,
            );
        },
    },
});

export const { setWarnings, addWarning, removeWarning } = warningsSlice.actions;

export default warningsSlice.reducer;
