// RealtimeCollapse.tsx
import React, { forwardRef } from 'react';
import { Collapse, Row, Col } from 'antd';
import HeaderWithApartmentTags from 'src/views/objects/HeaderWithApartmentTags';
import { useSelector } from 'react-redux';
import CommentList from 'src/views/objects/CommentList';
import { selectApartments } from 'src/store/reducers/selectedApartmentsSlice';
import { useWarnings } from 'src/hooks/comments/useWarnings';
import WarningList from './WarningList';

const { Panel } = Collapse;

type RealtimeCollapseProps = {
    objectId?: number;
    realtimeRawTemperatures?: any;
    commentListRef?: React.Ref<any>;
};

const CollapsibleCommentsView: React.FC<RealtimeCollapseProps> = forwardRef(
    (props, ref) => {
        const { objectId, realtimeRawTemperatures, commentListRef } = props;
        const selectedApartments: any = useSelector(selectApartments);
        //eslint-disable-next-line
        const { warnings } = useWarnings(objectId!);

        const latestId =
            Array.isArray(selectedApartments) && selectedApartments.length > 0
                ? selectedApartments[selectedApartments.length - 1]
                : null;

        const datapoint =
            latestId && Array.isArray(realtimeRawTemperatures)
                ? realtimeRawTemperatures.find(
                      (realtimeTemp: { id: any }) =>
                          realtimeTemp.id === latestId,
                  )
                : null;

        const relatedToId = datapoint
            ? `${objectId}:${datapoint.sensorFunctionTypeId}:${datapoint.sensorFunctionTypeIndex}`
            : null;

        return (
            <Collapse
                defaultActiveKey={['2']}
                className="collapse-realtime"
                bordered={false}
                style={{ background: '#fff', paddingBottom: '0px' }}
            >
                <Panel
                    header={
                        <HeaderWithApartmentTags
                            realtimeRawTemperatures={realtimeRawTemperatures}
                        />
                    }
                    key="2"
                >
                    <Row gutter={[20, 20]}>
                        <Col xs={24} md={24}>
                            {objectId && realtimeRawTemperatures && (
                                <WarningList
                                    objectId={objectId}
                                    relatedToId={datapoint?.id}
                                />
                            )}
                            {objectId && realtimeRawTemperatures && (
                                <CommentList
                                    ref={commentListRef}
                                    objectId={objectId}
                                    realtimeRawTemperatures={
                                        realtimeRawTemperatures
                                    }
                                    relatedToId={relatedToId}
                                />
                            )}
                        </Col>
                    </Row>
                </Panel>
            </Collapse>
        );
    },
);

export default CollapsibleCommentsView;
