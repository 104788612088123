import { Table, Typography, Tooltip, Input, Button } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TreeDataNode } from 'src/hooks/tree/useObjectTree';
import { useDispatch, useSelector } from 'react-redux';
import {
    addFloorOrBlockSensor,
    removeExistingFloorSensor,
} from 'src/store/reducers/selectedObjectSlice';
import {
    addToExistingRealtimeSensor,
    deleteExistingRealtimeSensor,
} from 'src/store/reducers/selectedRealtimeSensorsSlice';
import { useComments } from 'src/hooks/comments/useComments';
import { selectRealtimeSensors } from 'src/store/reducers/selectedRealtimeSensorsSlice';
import './RealtimeTable.scss';
import moment from 'moment';
import classNames from 'classnames';
import CommentListSensor from './CommentListSensor';
import { ReactComponent as CommentFilledIcon } from './comment-filled.svg';
import { ReactComponent as CommentOutlineIcon } from './comment-outline.svg';

interface RealtimeTableProps {
    realtimeRawTemperatures: any[];
    objectTree: TreeDataNode[];
    objectId: number;
}

const RealtimeTable = forwardRef((props: RealtimeTableProps, ref) => {
    const { realtimeRawTemperatures, objectTree, objectId } = props;

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const selectedRealtimeSensors = useSelector(selectRealtimeSensors);
    const { comments, fetchData } = useComments(objectId);
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
    const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
    const [nameSearchText, setNameSearchText] = useState<string>('');

    useEffect(() => {
        if (selectedRealtimeSensors && selectedRealtimeSensors.length > 0) {
            setSelectedRowKeys((prevSelectedRowKeys) => {
                const allKeys: string[] = [];
                selectedRealtimeSensors.forEach((sensorId: number) => {
                    const sensorIdToSearch = `${sensorId}`;
                    function traverseTree(nodes: TreeDataNode[]) {
                        for (const node of nodes) {
                            if (node.type === 'sensor') {
                                if (node.key.includes(sensorIdToSearch)) {
                                    allKeys.push(node.key);
                                    return;
                                }
                            }
                            if (node.children && node.children.length > 0) {
                                traverseTree(node.children);
                            }
                        }
                    }

                    traverseTree(objectTree);
                });

                const combinedKeys = new Set([
                    ...prevSelectedRowKeys,
                    ...allKeys,
                ]);

                return Array.from(combinedKeys);
            });
        }
        //eslint-disable-next-line
    }, [selectedRealtimeSensors]);

    const transformedData = (realtimeRawTemperatures || []).map(
        (item, index) => {
            const temperatureValues = item.data
                ? item.data.map((entry: any) => entry.value)
                : [];

            const deltaAverage = temperatureValues.length
                ? (
                      temperatureValues.reduce(
                          (sum: number, temp: number) =>
                              Number(sum) + Number(temp),
                          0,
                      ) / temperatureValues.length
                  ).toFixed(1)
                : undefined;

            const indoorTempMin = temperatureValues.length
                ? Math.min(...temperatureValues)
                : undefined;
            const indoorTempMax = temperatureValues.length
                ? Math.max(...temperatureValues)
                : undefined;

            const timestampLatest = item.data
                ? item.data[item.data.length - 1]?.timestamp
                : null;

            const minTimestamp = indoorTempMin
                ? item.data.find((entry: any) => entry.value === indoorTempMin)
                : null;

            return {
                key: `sensor-${item.id}-${item.objectId}`,
                id: item.id,
                isActive: item.isActive,
                name: item.name || item.name.substring(0, 10),
                title: item.name || item.name.substring(0, 10),
                indoorTemp: temperatureValues.length
                    ? temperatureValues[temperatureValues.length - 1].toFixed(1)
                    : undefined, // Last value
                deltaAverage,
                indoorTempMin: indoorTempMin
                    ? indoorTempMin.toFixed(1)
                    : undefined,
                indoorTempMax: indoorTempMax
                    ? indoorTempMax.toFixed(1)
                    : undefined,
                latestTimestamp: timestampLatest
                    ? moment(timestampLatest).format('YYYY-MM-DD HH:mm')
                    : null,
                minTimestamp: minTimestamp
                    ? moment(minTimestamp).format('YYYY-MM-DD HH:mm')
                    : null,
                sensorFunctionTypeId: item.sensorFunctionTypeId,
                sensorFunctionTypeIndex: item.sensorFunctionTypeIndex,
            };
        },
    );

    // Preparing additional data to append (blocks and sensors)
    const prepareAdditionalRows = () => {
        const blockRowsForFloors: any = [];

        const sensorCalculations = (sensorsToCalculate: any[]) => {
            const deltaAverages = sensorsToCalculate
                .filter(
                    (item) =>
                        !isNaN(Number(item.deltaAverage)) &&
                        item.deltaAverage !== undefined,
                )
                .map((item) => Number(item.deltaAverage));

            const indoorsTempsMin = sensorsToCalculate
                .filter(
                    (item) =>
                        !isNaN(item.indoorTempMin) &&
                        item.indoorTempMin !== undefined,
                )
                .map((item) => item.indoorTempMin);

            const indoorsTempsMax = sensorsToCalculate
                .filter(
                    (item) =>
                        !isNaN(item.indoorTempMax) &&
                        item.indoorTempMax !== undefined,
                )
                .map((item) => item.indoorTempMax);

            const deltaAverage = deltaAverages.length
                ? (
                      deltaAverages.reduce(
                          (sum: number, temp: number) =>
                              Number(sum) + Number(temp),
                          0,
                      ) / deltaAverages.length
                  ).toFixed(1)
                : undefined;

            const indoorTempMin = indoorsTempsMin.length
                ? Math.min(...indoorsTempsMin).toFixed(1)
                : undefined;
            const indoorTempMax = indoorsTempsMax.length
                ? Math.max(...indoorsTempsMax).toFixed(1)
                : undefined;

            return {
                deltaAverage: !isNaN(Number(deltaAverage))
                    ? deltaAverage
                    : undefined,
                indoorTempMin: !isNaN(Number(indoorTempMin))
                    ? indoorTempMin
                    : undefined,
                indoorTempMax: !isNaN(Number(indoorTempMax))
                    ? indoorTempMax
                    : undefined,
            };
        };

        const getAverageForBlockSensors = (
            sensors: number[],
            blockKey: string,
        ) => {
            const deltaAverages: any = [];
            for (let sensorId of sensors) {
                const currDeltaAvg = transformedData.find(
                    (item) => item.id === sensorId,
                )?.deltaAverage;
                if (currDeltaAvg && !isNaN(Number(currDeltaAvg))) {
                    deltaAverages.push(currDeltaAvg);
                }
            }

            const deltaAverage = deltaAverages.length
                ? (
                      deltaAverages.reduce(
                          (sum: number, temp: number) =>
                              Number(sum) + Number(temp),
                          0,
                      ) / deltaAverages.length
                  ).toFixed(1)
                : undefined;

            return deltaAverage;
        };

        objectTree.forEach((object) => {
            // Add rows for sensors under this object (block)
            if (object.children) {
                object.children.forEach((block) => {
                    if (block.type === 'block') {
                        const additionalRowsForFloorSensors: any = [];

                        block.children?.forEach((floor) => {
                            const floorSensors: any[] = [];
                            floor.children?.forEach((sensor) => {
                                const floorSensor = transformedData.find(
                                    (item) => item.id === sensor.id,
                                );

                                if (floorSensor) {
                                    const newFloorSensor = {
                                        ...floorSensor,
                                        key: `${sensor.key}`,
                                    };
                                    floorSensors.push(newFloorSensor);
                                }
                            });

                            if (floorSensors && floorSensors.length) {
                                const {
                                    deltaAverage,
                                    indoorTempMin,
                                    indoorTempMax,
                                } = sensorCalculations(floorSensors);

                                const indoorTemps =
                                    floorSensors
                                        .filter(
                                            (sensor) =>
                                                !isNaN(sensor.indoorTemp) &&
                                                sensor.indoorTemp !== undefined,
                                        )
                                        .map((sensor) => sensor.indoorTemp) ||
                                    [];

                                const indoorTempAvg = indoorTemps.length
                                    ? (
                                          indoorTemps.reduce(
                                              (sum: number, temp: number) =>
                                                  Number(sum) + Number(temp),
                                              0,
                                          ) / indoorTemps.length
                                      ).toFixed(1)
                                    : undefined;

                                additionalRowsForFloorSensors.push({
                                    id: `floor-${floor.id}`,
                                    key: `${floor.key}`,
                                    name: `${t('Floor')} ${
                                        Number(floor.title) - 10
                                    }`,
                                    title: `${object.title}-${block.title}-${t(
                                        'Floor',
                                    )} ${Number(floor.title) - 10}-${t(
                                        'Average',
                                    )}`,
                                    indoorTemp: indoorTempAvg,
                                    deltaAverage,
                                    indoorTempMin,
                                    indoorTempMax,
                                    children: floorSensors,
                                });
                            }
                        });

                        if (
                            additionalRowsForFloorSensors &&
                            additionalRowsForFloorSensors.length
                        ) {
                            const {
                                indoorTempMin,
                                indoorTempMax,
                            } = sensorCalculations(
                                additionalRowsForFloorSensors,
                            );

                            const indoorTemps =
                                additionalRowsForFloorSensors
                                    .filter(
                                        (sensor: any) =>
                                            !isNaN(sensor.indoorTemp) &&
                                            sensor.indoorTemp !== undefined,
                                    )
                                    .map((sensor: any) => sensor.indoorTemp) ||
                                [];

                            const indoorTempAvg = indoorTemps.length
                                ? (
                                      indoorTemps.reduce(
                                          (sum: number, temp: number) =>
                                              Number(sum) + Number(temp),
                                          0,
                                      ) / indoorTemps.length
                                  ).toFixed(1)
                                : undefined;

                            const blockSensors = additionalRowsForFloorSensors.flatMap(
                                (floor: any) =>
                                    floor?.children?.map(
                                        (child: any) => child.id,
                                    ) || [],
                            );

                            const blockRow = {
                                id: `block-${block.id}`,
                                key: `${block.key}`,
                                name: `${block.title}`,
                                title: `${object.title}-${block.title}-${t(
                                    'Average',
                                )}`,
                                indoorTemp: indoorTempAvg,
                                deltaAverage: getAverageForBlockSensors(
                                    blockSensors,
                                    `Block-${block.title}`,
                                ),
                                indoorTempMin,
                                indoorTempMax,
                                children: additionalRowsForFloorSensors,
                            };

                            blockRowsForFloors.push(blockRow);
                        }
                    }
                    if (block.type === 'others') {
                        const otherSensors: any[] = [];
                        block.children?.forEach((sensor) => {
                            const floorSensor = transformedData.find(
                                (item) => item.id === sensor.id,
                            );

                            if (floorSensor) {
                                const newFloorSensor = {
                                    ...floorSensor,
                                    key: `${sensor.key}`,
                                };
                                otherSensors.push(newFloorSensor);
                            }
                        });

                        if (otherSensors && otherSensors.length) {
                            const {
                                deltaAverage,
                                indoorTempMin,
                                indoorTempMax,
                            } = sensorCalculations(otherSensors);

                            const indoorTemps =
                                otherSensors
                                    .filter(
                                        (sensor) =>
                                            !isNaN(sensor.indoorTemp) &&
                                            sensor.indoorTemp !== undefined,
                                    )
                                    .map((sensor) => sensor.indoorTemp) || [];

                            const indoorTempAvg = indoorTemps.length
                                ? (
                                      indoorTemps.reduce(
                                          (sum: number, temp: number) =>
                                              Number(sum) + Number(temp),
                                          0,
                                      ) / indoorTemps.length
                                  ).toFixed(1)
                                : undefined;

                            blockRowsForFloors.push({
                                id: `others-${block.id}`,
                                key: `others-${block.id}`,
                                name: `Others-Sensors`,
                                title: `${object.title}-${block.title}`,
                                indoorTemp: indoorTempAvg,
                                deltaAverage,
                                indoorTempMin,
                                indoorTempMax,
                                children: otherSensors,
                            });
                        }
                    }
                });
            }
        });

        return !!blockRowsForFloors.length
            ? blockRowsForFloors
            : transformedData;
    };

    const filterByName = (value: string, data: any) => {
        const lowerValue = value.toLowerCase();
        const allRows: any[] = [];

        const traverseTree = (node: any) => {
            if (
                node.key.startsWith('sensor') &&
                node.name?.toLowerCase().includes(lowerValue)
            ) {
                allRows.push(node);
            }

            if (node.children && node.children.length) {
                node.children.forEach((child: any) => {
                    traverseTree(child);
                });
            }
        };

        data.forEach((record: any) => {
            traverseTree(record);
        });

        return allRows;
    };

    const mergedData = [...prepareAdditionalRows()];

    const filteredData =
        nameSearchText && nameSearchText.length
            ? filterByName(nameSearchText, mergedData)
            : mergedData;

    // Function to expand or collapse all rows
    const toggleExpandAll = () => {
        if (expandedRowKeys.length === 0) {
            // Recursively collect all keys for rows and nested rows
            const collectKeys = (data: any) => {
                let keys: string[] = [];
                data.forEach((record: any) => {
                    if (!record.key.startsWith('sensor')) {
                        keys.push(record.key);
                    }
                    if (record.children && record.children.length > 0) {
                        keys = keys.concat(collectKeys(record.children));
                    }
                });
                return keys;
            };

            const allKeys = collectKeys(filteredData);
            setExpandedRowKeys(allKeys);
        } else {
            // Collapse all rows
            setExpandedRowKeys([]);
        }
    };

    const columns = [
        {
            title: (
                <div className="flex items-center justify-between">
                    {/* <span>{t('Namn')}</span> */}
                    <Button
                        onClick={toggleExpandAll}
                        icon={
                            !!expandedRowKeys.length ? (
                                <MinusOutlined />
                            ) : (
                                <PlusOutlined />
                            )
                        }
                        size="middle"
                        style={{
                            position: 'relative',
                            top: '2px',
                            margin: '5px',
                        }}
                    />
                    <Input
                        placeholder="Search Name"
                        value={nameSearchText}
                        onChange={(e) => setNameSearchText(e.target.value)}
                        style={{ width: '100%', marginTop: 5 }}
                    />
                </div>
            ),
            dataIndex: 'name',
            width: '21ch',
            //sorter: (a: any, b: any) => a.name.localeCompare(b.name),
            render: (text: any, record: any, index: any) => {
                return (
                    <div>
                        <Tooltip title={record.name}>
                            <Typography.Text
                                style={
                                    record?.key?.startsWith('block') ||
                                    record?.key?.startsWith('others')
                                        ? {
                                              marginLeft: '10px',
                                              width: 'max-content',
                                          }
                                        : record?.key?.startsWith('floor')
                                        ? {
                                              marginLeft: '20px',
                                              width: 'max-content',
                                          }
                                        : record?.key?.startsWith('sensor')
                                        ? {
                                              marginLeft: '30px',
                                              display: 'inline-flex',
                                          }
                                        : { width: 'max-content' }
                                }
                            >
                                {text}
                            </Typography.Text>
                        </Tooltip>
                    </div>
                );
            },
        },
        {
            title: (
                <Tooltip title={t('LatestTemperature')}>
                    <div>
                        <span>{t('Temp')}</span>
                    </div>
                </Tooltip>
            ),
            width: '5ch',
            dataIndex: 'indoorTemp',
            showSorterTooltip: false,
            sorter: (a: any, b: any) => a.indoorTemp - b.indoorTemp,
            render: (text: any, record: any) => {
                const isSensor = record.key.startsWith('sensor');
                return isSensor ? (
                    <Tooltip title={record?.latestTimestamp}>
                        <div
                            style={{
                                whiteSpace: 'normal',
                                wordWrap: 'break-word',
                                display: 'inline-block',
                            }}
                        >
                            {text}
                        </div>
                    </Tooltip>
                ) : (
                    <div
                        style={{
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                            display: 'inline-block',
                        }}
                    >
                        {text}
                    </div>
                );
            },
        },
        {
            title: (
                <Tooltip title={t('AverageTemperature')}>
                    <div>
                        <span>{t('Avg')}</span>
                    </div>
                </Tooltip>
            ),
            width: '5ch',
            dataIndex: 'deltaAverage',
            sorter: (a: any, b: any) => a.deltaAverage - b.deltaAverage,
            showSorterTooltip: false,
            render: (text: any) => (
                <div
                    style={{
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                        display: 'inline-block',
                    }}
                >
                    {text}
                </div>
            ),
        },
        {
            title: (
                <Tooltip title={t('Minimum')}>
                    <div>
                        <span>{t('Min')}</span>
                    </div>
                </Tooltip>
            ),
            width: '5ch',
            dataIndex: 'indoorTempMin',
            sorter: (a: any, b: any) => a.indoorTempMin - b.indoorTempMin,
            showSorterTooltip: false,
            render: (text: any) => (
                <div
                    style={{
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                        display: 'inline-block',
                    }}
                >
                    {text}
                </div>
            ),
        },
        {
            title: (
                <Tooltip title={t('Maximum')}>
                    <div>
                        <span>{t('Max')}</span>
                    </div>
                </Tooltip>
            ),
            width: '5ch',
            dataIndex: 'indoorTempMax',
            sorter: (a: any, b: any) => a.indoorTempMax - b.indoorTempMax,
            showSorterTooltip: false,
            render: (text: any) => (
                <div
                    style={{
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                        display: 'inline-block',
                    }}
                >
                    {text}
                </div>
            ),
        },
        {
            title: '',
            dataIndex: '',
            render: () => <div />,
            width: '5ch',
        },
    ];

    const toggleSelectedKey = (
        prevSelectedKeys: string[],
        selectedKey: string,
    ): string[] => {
        if (prevSelectedKeys.includes(selectedKey)) {
            return prevSelectedKeys.filter((key) => key !== selectedKey);
        } else {
            return [...prevSelectedKeys, selectedKey];
        }
    };

    const handleRowSelection = (selectedKey: string, record: any) => {
        setSelectedRowKeys((prevSelectedKeys) => {
            const updatedKeys = toggleSelectedKey(
                prevSelectedKeys,
                selectedKey,
            );

            if (record?.key.startsWith('floor')) {
                if (prevSelectedKeys.includes(selectedKey)) {
                    dispatch(removeExistingFloorSensor(record.id));
                } else if (record?.children && record?.children.length) {
                    const selectedFloorsOrBlocks = {
                        identifier: record.title ? record.title : record.key,
                        id: record.id,
                        selectedFloors: record?.children.map(
                            (sensor: any) => sensor.id,
                        ),
                    };

                    dispatch(addFloorOrBlockSensor(selectedFloorsOrBlocks));
                }
            } else if (record?.key.startsWith('block')) {
                if (prevSelectedKeys.includes(selectedKey)) {
                    dispatch(removeExistingFloorSensor(record.id));
                } else if (record.children && record?.children.length) {
                    const sensors: any = [];
                    record?.children.forEach((floor: any) => {
                        floor.children.forEach((sensor: any) => {
                            sensors.push(sensor.id);
                        });
                    });

                    if (sensors && sensors.length) {
                        const selectedFloorsOrBlocks = {
                            identifier: record.title
                                ? record.title
                                : record.key,
                            id: record.id,
                            selectedFloors: sensors,
                        };
                        dispatch(addFloorOrBlockSensor(selectedFloorsOrBlocks));
                    }
                }
            } else if (record?.key.startsWith('sensor')) {
                if (prevSelectedKeys.includes(selectedKey)) {
                    dispatch(deleteExistingRealtimeSensor(record?.id));
                } else {
                    dispatch(addToExistingRealtimeSensor(record?.id));
                }
            }

            return updatedKeys;
        });
    };

    interface SensorRecord {
        key: string;
        name: string;
        indoorTemp: number;
        id: number;
        deltaAverage: number;
        indoorTempMin: number;
        indoorTempMax: number;
        sensorFunctionTypeId: number;
        sensorFunctionTypeIndex: number;
    }

    interface CustomRowProps {
        record: SensorRecord;
        handleRowSelection: (key: string, record: SensorRecord) => void;
    }

    const CustomRow: React.FC<
        CustomRowProps & {
            expandedRowKeys: string[];
            onToggleExpand: (key: string) => void;
        }
    > = ({ record, expandedRowKeys, onToggleExpand, handleRowSelection }) => {
        const isExpanded = expandedRowKeys.includes(record.key);
        const relatedtoId = `${objectId}:${record.sensorFunctionTypeId}:${record.sensorFunctionTypeIndex}`;
        const commentsToRender =
            comments?.filter(
                (comment) => comment.relatedToId === relatedtoId,
            ) || [];

        return (
            <>
                <tr
                    className={classNames({
                        'selected-row': selectedRowKeys.includes(record.key),
                    })}
                    onClick={() => handleRowSelection(record.key, record)}
                    style={{ cursor: 'pointer' }}
                >
                    <td
                        style={{
                            cursor: 'pointer',
                            //display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            width: '100%',
                        }}
                    >
                        <span
                            style={{
                                flex: 1,
                                textAlign: 'left',
                                marginLeft: `${
                                    !!expandedRowKeys.some((item) =>
                                        item.includes('block'),
                                    )
                                        ? '5rem'
                                        : '0px'
                                }`,
                            }}
                        >
                            {record.name}
                        </span>
                    </td>
                    <td style={{ textAlign: 'left' }}>{record.indoorTemp}</td>
                    <td style={{ textAlign: 'left' }}>{record.deltaAverage}</td>
                    <td style={{ textAlign: 'left' }}>
                        {record.indoorTempMin}
                    </td>
                    <td style={{ textAlign: 'left' }}>
                        {record.indoorTempMax}
                    </td>
                    <td
                        style={{
                            cursor: 'pointer',
                            width: '100%',
                            textAlign: 'center',
                        }}
                    >
                        <span
                            style={{
                                display: 'inline-flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                                height: '100%',
                            }}
                        >
                            {!!commentsToRender.length ? (
                                <CommentFilledIcon
                                    style={{
                                        cursor: 'pointer',
                                        width: '25px',
                                        height: '25px',
                                    }}
                                    onClick={(e: any) => {
                                        e.stopPropagation();
                                        onToggleExpand(record.key);
                                    }}
                                />
                            ) : (
                                <CommentOutlineIcon
                                    style={{
                                        cursor: 'pointer',
                                        width: '25px',
                                        height: '25px',
                                    }}
                                    onClick={(e: any) => {
                                        e.stopPropagation();
                                        onToggleExpand(record.key);
                                    }}
                                />
                            )}
                        </span>
                    </td>
                </tr>

                {isExpanded && (
                    <tr>
                        <td
                            colSpan={columns.length}
                            style={{ padding: '10px 0' }}
                        >
                            <div
                                style={{
                                    paddingTop: '10px',
                                    paddingBottom: '10px',
                                    marginLeft: `${
                                        !!expandedRowKeys.some((item) =>
                                            item.includes('block'),
                                        )
                                            ? '5.4rem'
                                            : '0px'
                                    }`,
                                    background: '#e8f5e9',
                                }}
                            >
                                {commentsToRender && (
                                    <CommentListSensor
                                        comments={commentsToRender}
                                        objectId={objectId}
                                        relatedToId={relatedtoId}
                                        mutate={fetchData}
                                    />
                                )}
                            </div>
                        </td>
                    </tr>
                )}
            </>
        );
    };

    const BodyWrapper: React.FC<
        React.HTMLAttributes<HTMLTableSectionElement>
    > = ({ children, ...props }) => {
        const customRenderedChildren = React.Children.map(
            children,
            (child: any) => {
                const rowKey = child?.key;
                // If the row has the "senschild" key, render it with custom logic
                if (rowKey?.includes('sensor')) {
                    const { record } = child?.props;
                    return (
                        <CustomRow
                            key={record.key}
                            record={record}
                            expandedRowKeys={expandedRowKeys}
                            onToggleExpand={(key) => {
                                if (expandedRowKeys.includes(key)) {
                                    setExpandedRowKeys((prev) =>
                                        prev.filter((k) => k !== key),
                                    );
                                } else {
                                    setExpandedRowKeys((prev) => [
                                        ...prev,
                                        key,
                                    ]);
                                }
                            }}
                            handleRowSelection={handleRowSelection}
                        />
                    );
                }

                return child;
            },
        );

        return <tbody {...props}>{customRenderedChildren}</tbody>;
    };

    const clearData = () => {
        setSelectedRowKeys([]);
    };

    useImperativeHandle(ref, () => ({
        clearData,
        toggleExpandAll,
    }));

    return (
        <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
            <Table
                size="small"
                //rowSelection={rowSelection}
                onRow={(record, rowIndex) => ({
                    onClick: () => {
                        handleRowSelection(record.key, record);
                    },
                })}
                pagination={false}
                components={{
                    body: {
                        wrapper: BodyWrapper,
                    },
                }}
                rowClassName={(record) => {
                    const isSelected = selectedRowKeys.includes(record.key);
                    return isSelected
                        ? 'selected-row'
                        : 'bg-white cursor-pointer';
                }}
                expandable={{
                    expandedRowKeys: expandedRowKeys,
                    onExpand: (expanded, record) => {
                        if (expanded) {
                            setExpandedRowKeys((prev) => [...prev, record.key]);
                        } else {
                            if (record.key.startsWith('floor')) {
                                setExpandedRowKeys((prev) => {
                                    const keysToRemove = [
                                        record.key,
                                        ...(record.children?.map(
                                            (child: any) => child.key,
                                        ) || []),
                                    ];
                                    return prev.filter(
                                        (key) => !keysToRemove.includes(key),
                                    );
                                });
                            } else {
                                setExpandedRowKeys((prev) =>
                                    prev.filter((key) => key !== record.key),
                                );
                            }
                        }
                    },
                    expandRowByClick: false,
                }}
                dataSource={filteredData}
                columns={columns}
                scroll={{ y: 300 }}
            />
        </div>
    );
});

export default RealtimeTable;
