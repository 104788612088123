import { API } from 'src/api/API';
import { EditDeviceDTO, Device } from 'src/models/Device';
import { mutate } from 'swr';

export const useEditDevice = () => {
    const edit = (
        objectId: number,
        prevDeviceId: number,
        newDevice: EditDeviceDTO,
    ): Promise<Device> => {
        return new API()
            .editDevice(objectId, prevDeviceId, newDevice)
            .then((v) => {
                mutate(`/objects/${objectId}/devices`);
                return v;
            });
    };
    return [edit] as const;
};
