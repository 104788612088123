import React, {
    useState,
    useRef,
    useEffect,
    useMemo,
    forwardRef,
    useImperativeHandle,
} from 'react';
import { Input, Button, message } from 'antd';
import { API } from 'src/api/API';
import moment from 'moment';
import { useComments } from 'src/hooks/comments/useComments';
import { useMe } from 'src/hooks/users/useMe';
import { useTranslation } from 'react-i18next';
import { useObject } from 'src/hooks/objects/useObject';

interface CommentListProps {
    objectId: number;
    realtimeRawTemperatures: any[];
    relatedToId: string | null;
}

const MAX_HEIGHT = 88;

const CommentList = forwardRef((props: CommentListProps, ref) => {
    const { objectId, realtimeRawTemperatures, relatedToId } = props;
    const { comments, fetchData } = useComments(objectId);
    const { data: object } = useObject(objectId);
    const [newComment, setNewComment] = useState<string>('');
    const [isViewAll, setIsViewAll] = useState<boolean>(false);
    const commentRef = useRef<HTMLDivElement>(null);
    const { t } = useTranslation();
    const { data: me } = useMe();

    const commentsToRender = relatedToId
        ? comments?.filter((comment) => comment.relatedToId === relatedToId) ||
          []
        : comments;

    const handleAddComment = async () => {
        if (!newComment.trim()) {
            message.warning('Please enter a comment!');
            return;
        }

        try {
            const comment = {
                text: newComment,
                userId: me?.user.id,
                relatedTo: relatedToId ? 'Sensor' : 'Object',
                relatedToId: relatedToId ? relatedToId : objectId,
            };

            await new API().createComment(objectId, comment);

            setNewComment('');
            message.success('Comment added successfully!');

            fetchData(objectId);
        } catch (error) {
            message.error('Failed to add comment');
        }
    };

    const handleViewAll = () => {
        setIsViewAll(true);
    };

    const handleViewLess = () => {
        setIsViewAll(false);
    };
    useEffect(() => {
        if (isViewAll) {
            commentRef.current?.scrollIntoView();
        }
    }, [isViewAll]);

    const { limitedComments, totalHeight } = useMemo(() => {
        if (!comments || !comments.length || relatedToId) {
            return { limitedComments: [], totalHeight: 0 };
        }

        let totalHeight = 0;
        let allHeight = 0;
        const limitedComments: any[] = [];
        for (let index = 0; index < comments.length; index++) {
            const comment = comments[index];

            const tempDiv = document.createElement('div');
            tempDiv.style.position = 'absolute';
            tempDiv.style.margin = '10px';
            tempDiv.style.visibility = 'hidden';
            tempDiv.style.height = 'auto';
            tempDiv.innerHTML = `
                <strong>${moment(comment.timestamp).format(
                    'YYYY-MM-DD HH:mm',
                )}</strong> <strong>${comment.name} - </strong>
                <strong>${comment.userName}:</strong>
                <div>${comment.text.replace(/(\r\n|\n|\r)/gm, '<br />')}</div>
            `;
            document.body.appendChild(tempDiv);
            const height = tempDiv.offsetHeight;
            document.body.removeChild(tempDiv);

            allHeight += height;

            if (totalHeight + height <= MAX_HEIGHT) {
                limitedComments.push(comment);
                totalHeight += height;
            } else if (limitedComments.length === 0 && height > MAX_HEIGHT) {
                limitedComments.push(comment);
                break;
            } else {
                break;
            }
        }

        return { limitedComments, totalHeight: allHeight };
    }, [comments, relatedToId]);

    let displayedComments =
        isViewAll || relatedToId ? commentsToRender : limitedComments;
    displayedComments = displayedComments?.map((item) => {
        if (item.relatedTo === 'Sensor') {
            const parts = item.relatedToId?.split(':') || [];
            if (parts.length === 3) {
                const [
                    //eslint-disable-next-line
                    objId,
                    sensorFunctionTypeId,
                    sensorFunctionTypeIndex,
                ] = parts;
                const sensor = realtimeRawTemperatures?.find(
                    (temp) =>
                        //eslint-disable-next-line
                        temp.sensorFunctionTypeId == sensorFunctionTypeId &&
                        //eslint-disable-next-line
                        temp.sensorFunctionTypeIndex == sensorFunctionTypeIndex,
                );
                return { ...item, name: sensor?.name || '' };
            } else {
                console.warn('Invalid relatedToId format:', item.relatedToId);
                return { ...item, name: '' };
            }
        } else {
            return { ...item, name: object?.name || '' };
        }
    });

    const scrollToAddComment = () => {
        setTimeout(() => commentRef.current?.scrollIntoView(), 500);
    };

    useImperativeHandle(ref, () => ({
        scrollToAddComment,
    }));

    return (
        <>
            <div
                className="overflow-auto"
                style={{
                    maxHeight: `${
                        !relatedToId && !isViewAll && totalHeight > MAX_HEIGHT
                            ? '120px'
                            : '24rem'
                    }`,
                }}
            >
                {displayedComments?.map((comment, index) => {
                    const date = moment(comment.timestamp);
                    const formattedDate = `${date.year()}-${date.format(
                        'MM',
                    )}-${date.format('DD')} ${date.format('HH:mm')}`;
                    const formattedText = comment.text.replace(
                        /(\r\n|\n|\r)/gm,
                        '<br />',
                    );

                    return (
                        <>
                            <div
                                key={index}
                                style={{
                                    margin: 10,
                                    color: 'blue',
                                    marginBottom: `${
                                        index === displayedComments.length - 1
                                            ? '0px'
                                            : '10px'
                                    }`,
                                }}
                            >
                                <strong>{formattedDate}</strong>{' '}
                                <strong>{comment.name} - </strong>
                                <strong>{comment.userName}:</strong>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: formattedText,
                                    }}
                                />
                            </div>
                        </>
                    );
                })}
            </div>
            {commentsToRender &&
                totalHeight > MAX_HEIGHT &&
                !isViewAll &&
                !relatedToId && (
                    <div className="flex justify-start">
                        <Button type="text" onClick={handleViewAll}>
                            {t('View all')}
                        </Button>
                    </div>
                )}

            {!!commentsToRender && isViewAll && !relatedToId && (
                <div className="flex justify-start">
                    <Button type="text" onClick={handleViewLess}>
                        {t('View less')}
                    </Button>
                </div>
            )}
            <hr />
            <div className="flex" ref={commentRef} style={{ margin: 10 }}>
                <Input.TextArea
                    value={newComment}
                    onChange={(e) => setNewComment(e.target.value)}
                    rows={2}
                    placeholder="Add a comment"
                />
                <Button
                    type="primary"
                    style={{ marginLeft: 10 }}
                    onClick={handleAddComment}
                >
                    Lägg till
                </Button>
            </div>
        </>
    );
});

export default CommentList;
