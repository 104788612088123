import { Button, Form, Input, Steps, message } from 'antd';
import React, { useState, useEffect, ChangeEvent } from 'react';
import { Auth } from 'aws-amplify';
import { API } from 'src/api/API';
import { useObject } from 'src/hooks/objects/useObject';
import Nav from 'src/utils/nav/Nav';
import { useNavigate } from 'react-router-dom';
import ModbusTableComponent from './ModbusTcpEditableTable';
import { useAddIntegration } from 'src/hooks/integrations/useAddIntegration';
import { useUpdateIntegration } from 'src/hooks/integrations/useUpdateIntegration';
import {
    setMasterModbusProperties,
    setMasterModbusData,
} from 'src/store/reducers/masterModbusTcpSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectModbus } from 'src/store/reducers/masterModbusTcpSlice';
import { useOrganizeModbusTcpOnIntegration } from 'src/hooks/integrations/useOrganizeModbusTcpIntegration';

interface Props {
    objectId: number;
    integratorId: number;
    integrationId?: number;
}

function IntegratorModbusTcpView(props: Props) {
    const { objectId, integratorId, integrationId } = props;

    const [addIntegration] = useAddIntegration();
    const [updateIntegration] = useUpdateIntegration();
    const [organize] = useOrganizeModbusTcpOnIntegration();

    const { data: object } = useObject(objectId);

    const history = useNavigate();

    const [selectedHost, setSelectedHost] = useState<string>('');
    const [selectedPort, setSelectedPort] = useState<string>('');
    const dispatch = useDispatch();

    const [formData, setFormData] = useState<any>({});
    const [currentStep, setCurrentStep] = useState<number>(0);

    const modbus = useSelector(selectModbus) || null;

    const [form] = Form.useForm();

    console.log(selectedHost);
    console.log(selectedPort);

    const handleFormFinish = (values: any) => {
        setFormData(values);
        setCurrentStep(currentStep + 1);
    };

    const handlePreviousStep = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    };

    const handleCreateIntegration = async (data: any) => {
        const result = await addIntegration(objectId, integratorId, formData);
        const integrationId = result['integrationId'];
        const organizeResult = await organize(objectId, integrationId, data);
        if (organizeResult && organizeResult.length) {
            message.success('Integration created successfully!');
        }
    };

    const handleEditIntegration = async (data: any) => {
        if (integrationId) {
            const updatedFormData = { ...formData };
            updatedFormData.integrationId = integrationId;
            updatedFormData.integratorId = integratorId;
            dispatch(setMasterModbusData(data));
            updateIntegration(objectId, integrationId, updatedFormData);
            const organizeResult = await organize(
                objectId,
                integrationId,
                data,
            );
            if (organizeResult && organizeResult.length) {
                message.success('Integration edited successfully!');
                if (object) {
                    history(
                        Nav.build({
                            path: '/manage-object',
                            queryParams: {
                                index: '1',
                                objectId: object.id.toString(),
                                siteId: object.siteId.toString(),
                                siteName: object.siteName,
                            },
                        }),
                    );
                }
            }
        }
    };

    useEffect(() => {
        if (modbus) {
            form.setFieldsValue({
                host: modbus?.master?.Host,
                port: modbus?.master?.Port,
            });
        }
    }, [modbus, form]);

    // useEffect to fetch data when integrationId changes
    useEffect(() => {
        const fetchData = async () => {
            const cus = await Auth.currentSession().catch(() => null);
            if (cus && cus.isValid()) {
                const authorization = cus
                    ? `Bearer ${cus.getAccessToken().getJwtToken()}`
                    : '';

                const headers = {
                    authorization,
                };
                fetch(
                    API.getURL(
                        `/objects/${objectId}/integrations/${integrationId}/list`,
                    ),
                    {
                        headers,
                    },
                ).then(async (v) => {
                    if (!v.ok) {
                        throw new Error((await v.json()).message);
                    }

                    const result = await v.json();
                    if (result?.master) {
                        dispatch(
                            setMasterModbusProperties({
                                id: result?.master?.id,
                                Host: result?.master?.Host,
                                Port: result?.master?.Port,
                            }),
                        );
                    }
                    if (result?.dataPoints) {
                        result.dataPoints = result.dataPoints.map(
                            (dataPoint: any, index: number) => {
                                return {
                                    ...dataPoint,
                                    key: index + 1,
                                    registerNumber: dataPoint.Register,
                                    registerType: dataPoint.RegisterType,
                                    scaling: dataPoint.scaleFactor,
                                    name: dataPoint.Name,
                                    sensorFunctionType:
                                        dataPoint.SensorFunctionType,
                                };
                            },
                        );
                        dispatch(setMasterModbusData(result?.dataPoints));
                    }
                });
            }
        };

        if (integrationId) {
            fetchData();
        }
    }, [dispatch, objectId, integrationId]);

    const steps = [
        {
            title: 'Modbus Tcp',
            content: (
                <>
                    {/* Form fields for Step 1 */}
                    <Form
                        onFinish={handleFormFinish}
                        form={form}
                        layout="vertical"
                    >
                        <Form.Item
                            name="host"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter host',
                                },
                            ]}
                            initialValue={modbus ? modbus?.master?.Host : null}
                        >
                            <Input
                                placeholder="Host"
                                style={{ width: '100%' }}
                                onChange={(
                                    e: ChangeEvent<HTMLInputElement>,
                                ) => {
                                    setSelectedHost(e.target.value);
                                }}
                            />
                        </Form.Item>

                        <Form.Item
                            name="port"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter port',
                                },
                            ]}
                            initialValue={modbus ? modbus?.master?.Port : null}
                        >
                            <Input
                                placeholder="Port"
                                style={{ width: '100%' }}
                                onChange={(
                                    e: ChangeEvent<HTMLInputElement>,
                                ) => {
                                    setSelectedPort(e.target.value);
                                }}
                            />
                        </Form.Item>

                        <Button type="primary" htmlType="submit">
                            Next
                        </Button>
                    </Form>
                </>
            ),
        },
        {
            title: 'Templates',
            content: (
                <>
                    <ModbusTableComponent
                        integrationId={integrationId}
                        currentStep={currentStep}
                        onPrevious={handlePreviousStep}
                        onIntegration={(data) => handleCreateIntegration(data)}
                        onEditIntegration={(data) =>
                            handleEditIntegration(data)
                        }
                    />
                </>
            ),
        },
    ];

    return (
        <>
            <div className="flex flex-wrap lg:flex-nowrap items-start gap-4 p-8">
                <div className="w-full max-w-full flex-shrink-0">
                    <Steps
                        current={currentStep}
                        style={{ marginBottom: '20px', width: '24rem' }}
                    >
                        {steps.map((step) => (
                            <Steps.Step key={step.title} title={step.title} />
                        ))}
                    </Steps>
                    {steps[currentStep].content}
                </div>
            </div>
        </>
    );
}

export default IntegratorModbusTcpView;
