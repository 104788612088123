import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'src/store/index';
import { RootState } from 'src/store/rootReducer';
import { API } from 'src/api/API';
import { setWarnings } from 'src/store/reducers/warningsSlice';

export const useWarnings = (objectId: number) => {
    const dispatch: AppDispatch = useDispatch();
    const allWarnings = useSelector(
        (state: RootState) => state.warnings.warnings,
    );
    const warnings =
        allWarnings?.filter((warning) => warning.objectId === objectId) || [];
    const api = new API();

    const fetchWarningData = async (objectId: number) => {
        try {
            const response = await api.fetchWarnings(objectId);
            dispatch(setWarnings(response));
        } catch (error) {
            console.error('Failed to fetch warnings:', error);
        }
    };

    useEffect(() => {
        fetchWarningData(objectId);
        //eslint-disable-next-line
    }, [objectId]);

    return { warnings, fetchWarningData };
};
