import { Auth } from 'aws-amplify';
import * as url from 'url';
import { env } from '../utils/env';
import { Corporation, CorporationRights } from 'src/models/Corporation';
import { User } from 'src/models/User';
import { SiteObject } from 'src/models/SiteObject';
import {
    CreateSensorDTO,
    Sensor,
    ChangeSensorDTO,
    SensorType,
} from 'src/models/Sensor';
import { CreateDeviceDTO, Device, EditDeviceDTO } from 'src/models/Device';
import { CreateObjectDTO } from 'src/hooks/sites/useAddObject';
import { EditObjectDTO } from 'src/hooks/objects/useEditObject';
import { ConfigSensorDTO } from 'src/hooks/sensors/useConfigSensor';
import {
    RealTimeResponse,
    RealTimeAvgPoint,
} from 'src/hooks/objects/useRealTime';
import { UpdateTemperatureDTO } from 'src/hooks/objects/useUpdateTemperature';
import { SetPointMap } from 'src/views/sites/FlowTemperatureView';
import { SensorWithAverage } from 'src/hooks/objects/useSensorAvergage';
import {
    BalanceData,
    HumidityAPIData,
} from 'src/views/objects/Balancing/BalancingView';
import { TemperatureWithControlState } from 'src/models/Temperature';
import { DOTdata } from 'src/hooks/objects/useGetDOT';
import { V3D_Object } from 'src/components/House3D/3d/chart/types';
import { Comment } from 'src/models/Comment';
import { Warning } from 'src/models/WarningDiagnostic';

export type CreateSite = {
    name: string;
};

interface FloorOrBlockSensor {
    identifier: string;
    selectedFloors: number[];
}

export class API {
    public static ENDPOINTS = {};

    private async getHeaders(headers = {}) {
        const cus = await Auth.currentSession().catch(() => null);
        return {
            ...(headers || {}),
            'Content-Type': 'application/json',
            authorization: cus
                ? `Bearer ${cus.getAccessToken().getJwtToken()}`
                : '',
        };
    }

    public static getURL(uri: string) {
        return url.resolve(env.REACT_APP_API, uri);
    }

    public static fetch<T>(
        url: string,
        init?: RequestInit | undefined,
    ): Promise<T | any> {
        return fetch(url, init).then(async (res) => {
            if (res.ok) {
                // Handles no content responses
                if (res.status === 204) {
                    return null;
                } else {
                    return res.json() as Promise<T>;
                }
            }
            return Promise.reject(await res.json());
        });
    }

    public async fetchRealTimePartion(
        sensors: number[],
        start: string,
        stop: string,
        id: number,
    ) {
        return API.fetch<RealTimeResponse[]>(
            API.getURL(`/realtime/${id}/selection?start=${start}&stop=${stop}`),
            {
                body: JSON.stringify(sensors),
                headers: await this.getHeaders(),
                method: 'POST',
            },
        );
    }

    public async addCorporation(corporation: Omit<Corporation, 'id'>) {
        return API.fetch<Corporation>(API.getURL('/corporations'), {
            body: JSON.stringify(corporation),
            headers: await this.getHeaders(),
            method: 'POST',
        });
    }

    public async updateTemperature(
        update: UpdateTemperatureDTO,
        objectId: number,
    ) {
        return API.fetch(
            API.getURL(
                `/objects/${objectId}/control-states/recommendation-type`,
            ),
            {
                body: JSON.stringify(update),
                headers: await this.getHeaders(),
                method: 'PUT',
            },
        );
    }

    public async configSensor(
        objectId: string,
        sensorId: string,
        configSensor: ConfigSensorDTO,
    ) {
        return API.fetch<Sensor>(
            API.getURL(`objects/${objectId}/sensors/${sensorId}`),
            {
                body: JSON.stringify(configSensor),
                headers: await this.getHeaders(),
                method: 'PUT',
            },
        );
    }

    public async setMetryMeter(
        objectId: number,
        meterId: string | null,
        metryIntegratorId: number | null,
        type: string,
    ) {
        return API.fetch<Corporation>(
            API.getURL(`external/metry/objects/${objectId}`),
            {
                body: JSON.stringify({
                    meterId,
                    type,
                    metryIntegratorId,
                }),
                headers: await this.getHeaders(),
                method: 'PUT',
            },
        );
    }

    public async removeMetry(corporationId: number) {
        return API.fetch<Corporation>(
            API.getURL(`external/metry/corporations/${corporationId}`),
            {
                headers: await this.getHeaders(),
                method: 'DELETE',
            },
        );
    }

    public async invalidateSensor(
        objectId: number,
        controlStateId: number,
        sensorId: number,
        isActive: boolean,
    ) {
        return API.fetch<RealTimeResponse>(
            API.getURL(
                `/objects/${objectId}/control-states/${controlStateId}/average-temperatures?sensorId=${sensorId}&isActive=${isActive}`,
            ),
            {
                headers: await this.getHeaders(),
                method: 'GET',
            },
        );
    }

    public async fetchRealTime(
        id: number,
        start: string,
        stop: string,
        type: SensorType,
    ) {
        return API.fetch<RealTimeResponse[]>(
            API.getURL(
                `/realtime/${id}/?start=${start}&stop=${stop}&type=${type}`,
            ),
            {
                headers: await this.getHeaders(),
                method: 'GET',
            },
        );
    }

    public async fetchRealTimeWithSensorTypes(
        id: number,
        start: string,
        stop: string,
        types: SensorType[],
        signal?: AbortSignal,
    ) {
        return API.fetch<RealTimeResponse[][]>(
            API.getURL(
                `/realtime/${id}/sensor-data/?start=${start}&stop=${stop}`,
            ),
            {
                body: JSON.stringify(types),
                headers: await this.getHeaders(),
                method: 'POST',
                signal,
            },
        );
    }

    public async fetchRealTimeWithAvgObj(
        id: number,
        start: string,
        stop: string,
        type: SensorType,
        signal?: AbortSignal,
    ) {
        return API.fetch<RealTimeResponse[]>(
            API.getURL(
                `/realtime/${id}/avg-obj/?start=${start}&stop=${stop}&type=${type}`,
            ),
            {
                headers: await this.getHeaders(),
                method: 'GET',
                signal,
            },
        );
    }

    public async fetchComments(objectId: number) {
        return API.fetch<Comment[]>(API.getURL(`/comments/${objectId}/all`), {
            headers: await this.getHeaders(),
            method: 'GET',
        });
    }

    public async fetchWarnings(objectId: number) {
        return API.fetch<Warning[]>(
            API.getURL(`/comments/${objectId}/diagnostics/warnings`),
            {
                headers: await this.getHeaders(),
                method: 'GET',
            },
        );
    }

    public async fetchHotNCold(
        id: number,
        start: string,
        stop: string,
        type: SensorType,
    ) {
        return API.fetch<RealTimeResponse[]>(
            API.getURL(
                `/realtime/${id}/hot-cold/?start=${start}&stop=${stop}&type=${type}`,
            ),
            {
                headers: await this.getHeaders(),
                method: 'GET',
            },
        );
    }

    public async fetchAvgFloor(
        objectId: number,
        start: string,
        stop: string,
        sensors: FloorOrBlockSensor[],
    ) {
        return API.fetch<RealTimeAvgPoint[]>(
            API.getURL(
                `/realtime/${objectId}/floor-avg/?start=${start}&stop=${stop}&type=101`,
            ),
            {
                body: JSON.stringify(sensors),
                headers: await this.getHeaders(),
                method: 'POST',
            },
        );
    }

    public async fetchMeasuredSensors(objectId: number) {
        return API.fetch<Sensor[]>(
            API.getURL(`/objects/${objectId}/measured-sensors`),
            {
                headers: await this.getHeaders(),
                method: 'GET',
            },
        );
    }

    public async fetchSpaces() {
        return API.fetch<Sensor[]>(API.getURL(`/mapping/spaces`), {
            headers: await this.getHeaders(),
            method: 'GET',
        });
    }

    public async fetchTemplateSpaces() {
        return API.fetch<any>(API.getURL(`/mapping/template-spaces`), {
            headers: await this.getHeaders(),
            method: 'GET',
        });
    }

    public async createBlocks(blocks: any) {
        return API.fetch<any>(API.getURL(`/mapping/blocks`), {
            body: JSON.stringify(blocks),
            headers: await this.getHeaders(),
            method: 'POST',
        });
    }

    public async createComment(objectId: number, comment: any) {
        return API.fetch<any>(API.getURL(`comments/${objectId}/add`), {
            body: JSON.stringify(comment),
            headers: await this.getHeaders(),
            method: 'POST',
        });
    }

    public async initMetry(
        corporationId: number,
        code: string,
        redirectURI: string,
    ) {
        return API.fetch<Corporation>(
            API.getURL(`external/metry/corporations/${corporationId}/init`),
            {
                body: JSON.stringify({
                    code,
                    redirectURI,
                }),
                headers: await this.getHeaders(),
                method: 'POST',
            },
        );
    }

    public async updateUser(user: User) {
        return API.fetch<User>(API.getURL('/users/' + user.id), {
            body: JSON.stringify(user),
            headers: await this.getHeaders(),
            method: 'PUT',
        });
    }

    public async updateDevice(
        objectId: number,
        sensorId: number,
        sensor: Sensor,
    ) {
        return API.fetch<User>(
            API.getURL(`/objects/${objectId}/sensors/${sensorId}`),
            {
                body: JSON.stringify(sensor),
                headers: await this.getHeaders(),
                method: 'PUT',
            },
        );
    }

    public async updateDesiredTemperature(
        objectId: number,
        data: Record<number, number>,
        stateId: number,
    ) {
        return API.fetch<User>(
            API.getURL(
                `objects/${objectId}/control-states/${stateId}/desired-temperatures`,
            ),
            {
                body: JSON.stringify(data),
                headers: await this.getHeaders(),
                method: 'PUT',
            },
        );
    }

    public async getRecommendation(objectId: number, controlStateId: number) {
        return API.fetch<{ table: SetPointMap[]; type: 'DELTA' | 'ABSOLUTE' }>(
            API.getURL(
                `/objects/${objectId}/control-states/${controlStateId}/control-system/recommendation-type`,
            ),
            {
                headers: await this.getHeaders(),
                method: 'GET',
            },
        );
    }

    public async addMockTemperature(
        objectId: number,
        data: Record<number, number>,
    ) {
        return API.fetch<User>(
            API.getURL(`/objects/.dev/${objectId}/mock-temperatures`),
            {
                body: JSON.stringify(data),
                headers: await this.getHeaders(),
                method: 'POST',
            },
        );
    }

    public async updateCorporation(corporation: Corporation) {
        return API.fetch<Corporation>(
            API.getURL('/corporations/' + corporation.id),
            {
                body: JSON.stringify(corporation),
                headers: await this.getHeaders(),
                method: 'PUT',
            },
        );
    }

    public async createReport(objectId: number, controlId: number) {
        return API.fetch<any>(
            API.getURL(`/objects/${objectId}/download-report`),
            {
                headers: await this.getHeaders(),
                method: 'POST',
                body: JSON.stringify({
                    controlId,
                }),
            },
        );
    }

    public async sendEmail(objectName: string, receiver: string, object: any) {
        return API.fetch<any>(API.getURL(`/objects/send-email`), {
            body: JSON.stringify({
                reportName: objectName,
                receiver,
                objectName: object?.name,
                siteName: object?.siteName,
            }),
            headers: await this.getHeaders(),
            method: 'POST',
        });
    }

    public async updateCorporationRights(
        corporationId: number,
        userId: number,
        rights: CorporationRights[],
    ) {
        return API.fetch<Corporation>(
            API.getURL(`/corporations/${corporationId}/users/${userId}`),
            {
                body: JSON.stringify({ rights }),
                headers: await this.getHeaders(),
                method: 'PUT',
            },
        );
    }

    public async signUpUser(user: Omit<User, 'id'> & { password: string }) {
        return API.fetch<Corporation>(API.getURL('/users/'), {
            body: JSON.stringify(user),
            headers: await this.getHeaders(),
            method: 'POST',
        });
    }

    public async addUser(
        corpertaionId: number,
        user: Omit<User, 'id'>,
        rights: CorporationRights[] = [CorporationRights.GET_CORPORATION],
    ) {
        return API.fetch<Corporation>(
            API.getURL(`corporations/${corpertaionId}/add-user`),
            {
                body: JSON.stringify({
                    user,
                    rights,
                }),
                headers: await this.getHeaders(),
                method: 'POST',
            },
        );
    }

    public async addSite(corporationid: number, site: CreateSite) {
        return API.fetch<Corporation>(
            API.getURL(`corporations/${corporationid}/sites`),
            {
                body: JSON.stringify(site),
                headers: await this.getHeaders(),
                method: 'POST',
            },
        );
    }

    public async addObject(siteid: number, object: CreateObjectDTO) {
        return API.fetch<SiteObject>(API.getURL(`sites/${siteid}/objects`), {
            body: JSON.stringify(object),
            headers: await this.getHeaders(),
            method: 'POST',
        });
    }

    public async addSensor(sensor: CreateSensorDTO, objectId: number) {
        return API.fetch<Corporation>(
            API.getURL(`/objects/${objectId}/sensors`),
            {
                body: JSON.stringify(sensor),
                headers: await this.getHeaders(),
                method: 'POST',
            },
        );
    }

    public async checkSensorCollisions(mac: string, objectId: number) {
        return API.fetch<Corporation>(
            API.getURL(`/objects/${objectId}/sensors/check-collisions/${mac}`),
            {
                headers: await this.getHeaders(),
                method: 'GET',
            },
        );
    }

    public async deleteCorporation(id: number) {
        return API.fetch<Corporation>(API.getURL('/corporations/' + id), {
            headers: await this.getHeaders(),
            method: 'DELETE',
        });
    }

    public async removeSiteFromCorporation(
        corporationId: number,
        siteId: number,
    ) {
        return API.fetch<Corporation>(
            API.getURL(`/corporations/${corporationId}/sites/${siteId}`),
            {
                headers: await this.getHeaders(),
                method: 'DELETE',
            },
        );
    }

    public async deleteUser(id: number) {
        return API.fetch<User>(API.getURL('/users/' + id), {
            headers: await this.getHeaders(),
            method: 'DELETE',
        });
    }

    public async commitStateChanges(objectId: number) {
        return API.fetch<void>(API.getURL(`/objects/${objectId}/commit`), {
            headers: await this.getHeaders(),
            method: 'POST',
        });
    }

    public async deleteSensor(objectId: number, sensorId: number) {
        return API.fetch(
            API.getURL(`/objects/${objectId}/sensors/${sensorId}`),
            {
                headers: await this.getHeaders(),
                method: 'DELETE',
            },
        );
    }

    public async changeSensor(
        objectId: number,
        prevSensorId: number,
        newSensor: ChangeSensorDTO,
    ) {
        return API.fetch<Sensor>(
            API.getURL(`/objects/${objectId}/sensors/replace/${prevSensorId}`),
            {
                headers: await this.getHeaders(),
                method: 'PUT',
                body: JSON.stringify(newSensor),
            },
        );
    }

    public async changeDevice(
        objectId: number,
        prevDeviceId: number,
        newDevice: CreateDeviceDTO,
    ) {
        return API.fetch<Device>(
            API.getURL(`/objects/${objectId}/devices/replace/${prevDeviceId}`),
            {
                headers: await this.getHeaders(),
                method: 'PUT',
                body: JSON.stringify(newDevice),
            },
        );
    }

    public async editDevice(
        objectId: number,
        deviceId: number,
        newDevice: EditDeviceDTO,
    ) {
        return API.fetch<Device>(
            API.getURL(`/objects/${objectId}/devices/edit/${deviceId}`),
            {
                headers: await this.getHeaders(),
                method: 'PUT',
                body: JSON.stringify(newDevice),
            },
        );
    }

    public async addDevice(objectId: number, device: CreateDeviceDTO) {
        return API.fetch<Device>(API.getURL(`/objects/${objectId}/devices`), {
            body: JSON.stringify(device),
            headers: await this.getHeaders(),
            method: 'POST',
        });
    }

    public async deleteDevice(objectId: number, deviceId: number) {
        return API.fetch<any>(
            API.getURL(`/objects/${objectId}/devices/${deviceId}`),
            {
                headers: await this.getHeaders(),
                method: 'DELETE',
            },
        );
    }

    public async getHolistic(
        object: Omit<
            SiteObject,
            | 'co2EmissionFactorElectricity'
            | 'co2EmissionFactorHeating'
            | 'outsideWalls'
            | 'siteId'
            | 'siteName'
        >,
    ) {
        return API.fetch<any>(API.getURL(`/calculator/savings/holistic`), {
            body: JSON.stringify(object),
            headers: await this.getHeaders(),
            method: 'POST',
        });
    }

    public async updateSite(siteID: number, site: CreateSite) {
        return API.fetch<Device>(API.getURL(`/sites/${siteID}`), {
            body: JSON.stringify(site),
            headers: await this.getHeaders(),
            method: 'PUT',
        });
    }

    public async updateObject(objectId: number, object: EditObjectDTO) {
        return API.fetch<Device>(API.getURL(`/objects/${objectId}`), {
            body: JSON.stringify(object),
            headers: await this.getHeaders(),
            method: 'PUT',
        });
    }

    public async createIntegration(
        objectId: number,
        integratorId: number,
        credentials: any,
    ) {
        return API.fetch<any>(API.getURL(`/objects/${objectId}/integrations`), {
            body: JSON.stringify({
                integratorId,
                ...credentials,
            }),
            headers: await this.getHeaders(),
            method: 'POST',
        });
    }

    public async updateIntegration(
        objectId: number,
        integratorId: number,
        credentials: any,
    ) {
        return API.fetch<any>(API.getURL(`/objects/${objectId}/integrations`), {
            body: JSON.stringify({
                integratorId,
                ...credentials,
            }),
            headers: await this.getHeaders(),
            method: 'PUT',
        });
    }

    public async organizeIntegration(
        objectId: number,
        integratorId: number,
        tagTree: any,
    ) {
        return API.fetch<any>(
            API.getURL(
                `/objects/${objectId}/integrations/${integratorId}/organize`,
            ),
            {
                body: JSON.stringify(tagTree),
                headers: await this.getHeaders(),
                method: 'POST',
            },
        );
    }

    public async organizeIntegrationModbus(
        objectId: number,
        integratorId: number,
        data: any,
    ) {
        return API.fetch<any>(
            API.getURL(
                `/objects/${objectId}/integrations/${integratorId}/organize-modbus`,
            ),
            {
                body: JSON.stringify(data),
                headers: await this.getHeaders(),
                method: 'POST',
            },
        );
    }

    public async addOrUpdateModularConfig(objectId: number, data: any) {
        return API.fetch<any>(
            API.getURL(`/objects/${objectId}/modulator-config`),
            {
                body: JSON.stringify(data),
                headers: await this.getHeaders(),
                method: 'POST',
            },
        );
    }

    public async organizeIntegrationModbusTcp(
        objectId: number,
        integratorId: number,
        data: any,
    ) {
        return API.fetch<any>(
            API.getURL(
                `/objects/${objectId}/integrations/${integratorId}/organize-modbus-tcp`,
            ),
            {
                body: JSON.stringify(data),
                headers: await this.getHeaders(),
                method: 'POST',
            },
        );
    }

    public async getObjectVisualization(objectId: number): Promise<V3D_Object> {
        return API.fetch<V3D_Object>(
            API.getURL(`/objects/${objectId}/visualization`),
            {
                headers: await this.getHeaders(),
                method: 'GET',
            },
        );
    }

    public async readSetpointsOnIntegration(objectId: number) {
        return API.fetch<any>(
            API.getURL(`/objects/${objectId}/integrations/read/setpoints`),
            {
                headers: await this.getHeaders(),
                method: 'GET',
            },
        );
    }

    public async fetchSensorsWithAverage(
        objectId: number,
        stateId: number,
        sensorId: number,
    ): Promise<SensorWithAverage> {
        return API.fetch<SensorWithAverage>(
            API.getURL(
                `/objects/${objectId}/control-states/${stateId}/temperatures/${sensorId}`,
            ),
            {
                headers: await this.getHeaders(),
                method: 'GET',
            },
        );
    }

    public async fetchMultipleSensorsWithAverage(
        objectId: number,
        stateId: number,
        sensorIds: { sensors: number[] },
    ): Promise<SensorWithAverage[]> {
        return API.fetch<SensorWithAverage[]>(
            API.getURL(
                `/objects/${objectId}/control-states/${stateId}/temperatures-multiple-sensors`,
            ),
            {
                headers: await this.getHeaders(),
                method: 'POST',
                body: JSON.stringify(sensorIds),
            },
        );
    }

    // returns balancdata
    public async fetchBalancingData(
        objectId: number,
        start: string,
        stop: string,
        stateId: number,
    ): Promise<BalanceData[]> {
        return API.fetch<BalanceData[]>(
            API.getURL(
                `/realtime/${objectId}/advanced?start=${start}&stop=${stop}&control_state=${stateId}`,
            ),
            {
                headers: await this.getHeaders(),
                method: 'GET',
            },
        );
    }

    // returns humiditydata
    public async fetchHumidityData(
        objectId: number,
        stateId: number,
    ): Promise<HumidityAPIData> {
        return API.fetch<HumidityAPIData[]>(
            API.getURL(
                `objects/${objectId}/control-states/${stateId}/humidity`,
            ),
            {
                headers: await this.getHeaders(),
                method: 'GET',
            },
        );
    }

    public async fetchDOT(
        objectId: number,
        controlStateId: number,
    ): Promise<DOTdata> {
        return API.fetch(
            API.getURL(
                `/objects/${objectId}/control-states/${controlStateId}/dot`,
            ),
            { headers: await this.getHeaders(), method: 'GET' },
        );
    }

    public async fetchAverageTempertureWithControlState(
        objectId: number,
        controlState: number,
    ): Promise<TemperatureWithControlState> {
        return API.fetch<TemperatureWithControlState>(
            API.getURL(
                `objects/${objectId}/control-states/${controlState}/average-temperatures`,
            ),
            {
                headers: await this.getHeaders(),
                method: 'GET',
            },
        );
    }

    public async fetchObjectVisualization(
        objectId: number,
        stateId: number,
    ): Promise<V3D_Object> {
        return API.fetch(
            API.getURL(
                `objects/${objectId}/control-states/${stateId}/visualization`,
            ),
            {
                headers: await this.getHeaders(),
                method: 'GET',
            },
        );
    }

    public async fetchObjectTree(objectId: number): Promise<any> {
        return API.fetch(API.getURL(`sites/site-tree/${objectId}`), {
            headers: await this.getHeaders(),
            method: 'GET',
        });
    }

    public async fetchObjectRealTimeVisualization(
        objectId: number,
        start: string,
        stateId: number,
    ): Promise<V3D_Object> {
        return API.fetch(
            API.getURL(
                `objects/${objectId}/control-states/${stateId}/visualization-realtime?start=${start.trim()}`,
            ),
            {
                headers: await this.getHeaders(),
                method: 'GET',
            },
        );
    }

    public async fetchTemperaturesRealTimeVisualization(
        objectId: number,
        start: string,
        end: string,
        signal?: AbortSignal, // Accept signal as an optional parameter
    ): Promise<any> {
        return API.fetch(
            API.getURL(
                `objects/${objectId}/visualization-realtime-temperatures?start=${start.trim()}&stop=${end.trim()}`,
            ),
            {
                headers: await this.getHeaders(),
                method: 'GET',
                signal,
            },
        );
    }

    public async fetchTemperaturesRealTimeVisualizationSite(body: {
        object_ids: number[];
        start: string;
        end: string;
    }): Promise<any> {
        return API.fetch(
            API.getURL(`objects/visualization-realtime-temperatures-site`),
            {
                body: JSON.stringify(body),
                headers: await this.getHeaders(),
                method: 'POST',
            },
        );
    }
}
