import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'src/store/index';
import { RootState } from 'src/store/rootReducer';
import { API } from 'src/api/API';
import { setComments } from 'src/store/reducers/commentsSlice';

export const useComments = (objectId: number) => {
    const dispatch: AppDispatch = useDispatch();
    const allComments = useSelector(
        (state: RootState) => state.comments.comments,
    );
    const comments =
        allComments?.filter((comment) => comment.objectId === objectId) || [];
    const api = new API();

    const fetchData = async (objectId: number) => {
        try {
            const response = await api.fetchComments(objectId);
            dispatch(setComments(response));
        } catch (error) {
            console.error('Failed to fetch comments:', error);
        }
    };

    useEffect(() => {
        fetchData(objectId);
        //eslint-disable-next-line
    }, [objectId]);

    return { comments, fetchData };
};
