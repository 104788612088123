import { combineReducers } from '@reduxjs/toolkit';

// Reducers
import sensorsReducer from 'src/store/reducers/sensorsSlice';
import sensorNamesReducer from 'src/store/reducers/sensorNamesSlice';
import selectedSensorsReducer from 'src/store/reducers/selectedSensorsSlice';
import selectedRealtimeSensorReducer from 'src/store/reducers/selectedRealtimeSensorsSlice';
import masterModbusReducer from 'src/store/reducers/masterModbusSlice';
import datePickerReducer from 'src/store/reducers/datePickerSlice';
import selectedKeysReducer from 'src/store/reducers/selectedKeysSlice';
import selectedObjectReducer from 'src/store/reducers/selectedObjectSlice';
import treeReducer from 'src/store/reducers/treeSlice';
import masterModbusTcpReducer from 'src/store/reducers/masterModbusTcpSlice';
import tableRowsReducer from 'src/store/reducers/tableRowsSlice';
import templateSpacesReducer from 'src/store/reducers/templateSpacesSlice';
import cameraSettingsReducer from 'src/store/reducers/cameraSettingsSlice';
import realtimeReducer from 'src/store/reducers/realTimeVisualization';
import realtimeTemperaturesReducer from 'src/store/reducers/realtimeTemperturesSlice';
import realtimeRawTemperaturesReducer from 'src/store/reducers/realtimeRawTemperaturesSlice';
import dateReducer from 'src/store/reducers/dateSlice';
import visualizationReducer from 'src/store/reducers/visualizationSlice';
import avgObjTemperaturesReducer from 'src/store/reducers/avgObjTemperaturesSlice';
import sensorAverageReducer from 'src/store/reducers/sensorAverageSlice';
import avgTemperaturesControlStateReducer from 'src/store/reducers/avgTemperaturesControlStateSlice';
import userCorporationsReducer from 'src/store/reducers/userCorporationsSlice';
import modulatorConfigReducer from 'src/store/reducers/modulatorConfigSlice';
import selectedApartmentsReducer from 'src/store/reducers/selectedApartmentsSlice';
import commentsReducer from 'src/store/reducers/commentsSlice';
import warningsReducer from 'src/store/reducers/warningsSlice';

const rootReducer = combineReducers({
    sensors: sensorsReducer,
    sensorNames: sensorNamesReducer,
    masterModbus: masterModbusReducer,
    masterModbusTcp: masterModbusTcpReducer,
    modulatorConfig: modulatorConfigReducer,
    cameraSettings: cameraSettingsReducer,
    datePicker: datePickerReducer,
    selectedSensors: selectedSensorsReducer,
    selectedRealtimeSensors: selectedRealtimeSensorReducer,
    selectedKeys: selectedKeysReducer,
    selectedObject: selectedObjectReducer,
    tree: treeReducer,
    tableRows: tableRowsReducer,
    templateSpaces: templateSpacesReducer,
    realtimeVisualization: realtimeReducer,
    realtimeTemperatures: realtimeTemperaturesReducer,
    realtimeRawTemperatures: realtimeRawTemperaturesReducer,
    date: dateReducer,
    comments: commentsReducer,
    warnings: warningsReducer,
    avgObjTemperatures: avgObjTemperaturesReducer,
    visualization: visualizationReducer,
    sensorAverage: sensorAverageReducer,
    avgTemperaturesControlState: avgTemperaturesControlStateReducer,
    userCorporations: userCorporationsReducer,
    selectedApartments: selectedApartmentsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
