import { useState } from 'react';
import { API } from 'src/api/API';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedRealtimeSensors } from 'src/store/reducers/selectedRealtimeSensorsSlice';
import { TreeDataNode, selectTreeData } from 'src/store/reducers/treeSlice';
import {
    setSelectedKeys,
    clearSelectedKeys,
} from 'src/store/reducers/selectedKeysSlice';

const findNodeByLastSegment = (
    node: TreeDataNode,
    targetSuffix: string,
): TreeDataNode | null => {
    const nodeKeySegments = String(node.key).split('-');
    const nodeLastSegment = `${nodeKeySegments.slice(-2).join('-')}`;

    if (nodeLastSegment === targetSuffix) {
        return node;
    }

    if (node.children) {
        for (const child of node.children) {
            const foundNode = findNodeByLastSegment(child, targetSuffix);
            if (foundNode) {
                return foundNode;
            }
        }
    }

    return null;
};

const findNodeByLastSegmentInTree = (
    allTreeData: TreeDataNode[],
    targetSuffix: string,
): TreeDataNode | null => {
    for (const node of allTreeData) {
        const foundNode = findNodeByLastSegment(node, targetSuffix);
        if (foundNode) {
            return foundNode;
        }
    }

    return null;
};

const useHotNColdRealtime = () => {
    const [error, setError] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const dispatch = useDispatch();
    const treeData = useSelector(selectTreeData);

    const fetchHotNCold = async (
        objectId: number,
        start: string,
        stop: string,
        shouldSelectedKeys: boolean,
    ) => {
        setIsLoading(true);
        try {
            const response = await new API().fetchHotNCold(
                objectId,
                start,
                stop,
                101,
            );
            const allHotColdSensorIds = [];
            if (response.hot && response.hot.length) {
                allHotColdSensorIds.push(
                    ...(response.hot.map((it: any) => it.id) || []),
                );
            }

            if (response.cold && response.cold.length) {
                allHotColdSensorIds.push(
                    ...(response.cold.map((it: any) => it.id) || []),
                );
            }

            if (shouldSelectedKeys) {
                dispatch(setSelectedRealtimeSensors(allHotColdSensorIds));
            }

            const newSelectedKeys: string[] = [];

            // this code will be deleted later on after deleting the navigation tree pane
            if (allHotColdSensorIds && allHotColdSensorIds.length) {
                const selectedKeys =
                    allHotColdSensorIds.map((it: any) => it + '-' + objectId) ||
                    [];

                for (let selectedKey of selectedKeys) {
                    const node = findNodeByLastSegmentInTree(
                        treeData,
                        selectedKey,
                    );
                    if (node && node.key) {
                        newSelectedKeys.push(node.key);
                    }
                }

                if (shouldSelectedKeys) {
                    dispatch(setSelectedKeys(newSelectedKeys));
                }
            } else {
                dispatch(clearSelectedKeys());
            }
        } catch (e: any) {
            setError(e?.message);
        } finally {
            setIsLoading(false);
        }
    };

    return { error, isLoading, fetchHotNCold };
};

export default useHotNColdRealtime;
