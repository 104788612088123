import { Button, Form, Select, message } from 'antd';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDevicesSerialNumbers } from 'src/hooks/objects/useDevicesSerialNumber';
import { useDispatch, useSelector } from 'react-redux';
import { Auth } from 'aws-amplify';
import { API } from 'src/api/API';
import { showError } from 'src/utils/showError';
import {
    setModulatorConfigData,
    setModulatorConfigProperties,
} from 'src/store/reducers/modulatorConfigSlice';
import { selectConfig } from 'src/store/reducers/modulatorConfigSlice';
import ModulatorConfigTable, { TableRow } from './ModulatorConfigTable';
import { useModulatorConfigApi } from 'src/hooks/objects/useModulatorConfig';

interface Props {
    objectId: number;
}

function ModulatorConfigView(props: Props) {
    const { objectId } = props;

    const tableRef = useRef<{ getData: () => TableRow[] }>(null);

    const { t } = useTranslation();

    const { data: devices } = useDevicesSerialNumbers(objectId);
    const dispatch = useDispatch();

    const [addOrUpdate] = useModulatorConfigApi();

    const modulatorConfig = useSelector(selectConfig) || null;

    const [configId, setConfigId] = useState<number | undefined>(undefined);

    const [form] = Form.useForm();

    const handleFormFinish = async (values: any) => {
        if (tableRef.current) {
            const data = tableRef.current.getData();

            if (data && data.length) {
                if (configId) {
                    const dataToSubmit = {
                        config: { ...values, id: modulatorConfig.config.id },
                        data,
                    };
                    const result = await addOrUpdate(objectId, dataToSubmit);
                    if (result && result.status && result.status === 'ok') {
                        message.success(
                            'Modulator config edited successfully!',
                        );
                    }
                } else {
                    const dataToSubmit = {
                        config: { ...values },
                        data,
                    };
                    const result = await addOrUpdate(objectId, dataToSubmit);
                    if (result && result.status && result.status === 'ok') {
                        message.success(
                            'Modulator config created successfully!',
                        );
                    }
                }
            }
        }
    };

    const handleCreateConfig = async (data: any) => {
        const dataToSubmit = {
            data,
        };
        const result = await addOrUpdate(objectId, dataToSubmit);
        if (result && result.status && result.status === 'ok') {
            message.success('Modulator config created successfully!');
        }
    };

    const handleEditConfig = async (data: any) => {
        const dataToSubmit = {
            config: { id: modulatorConfig.config.id },
            data,
        };
        const result = await addOrUpdate(objectId, dataToSubmit);
        if (result && result.status && result.status === 'ok') {
            message.success('Modulator config edited successfully!');
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            const cus = await Auth.currentSession().catch(() => null);
            if (cus && cus.isValid()) {
                const authorization = cus
                    ? `Bearer ${cus.getAccessToken().getJwtToken()}`
                    : '';

                const headers = {
                    authorization,
                };
                fetch(API.getURL(`/objects/${objectId}/modulator-config`), {
                    headers,
                })
                    .then(async (v) => {
                        if (!v.ok) {
                            throw new Error((await v.json()).message);
                        }

                        const result = await v.json();
                        if (result && result.config && result.config.length) {
                            const config = result.config[0];

                            dispatch(
                                setModulatorConfigProperties({
                                    id: config?.id,
                                    deviceSerialNumber:
                                        config?.deviceSerialNumber,
                                    requestedEmulationStatus:
                                        config?.requestedEmulationStatus,
                                    sensorElement: config?.sensorElement,
                                }),
                            );

                            const data = result.config.map(
                                (dataPoint: any, index: number) => {
                                    return {
                                        key: index + 1,
                                        offsetId: dataPoint.offsetId,
                                        controlState: dataPoint.controlState,
                                        out: dataPoint.out,
                                        offsetValue: dataPoint.offsetValue,
                                    };
                                },
                            );
                            dispatch(setModulatorConfigData(data));
                        }
                    })
                    .catch((error) => {
                        showError(error?.message, '');
                    });
            }
        };

        fetchData();
    }, [dispatch, objectId]);

    useEffect(() => {
        if (modulatorConfig) {
            form.setFieldsValue({
                deviceSerialNumber: modulatorConfig?.config?.deviceSerialNumber,
                requestedEmulationStatus:
                    modulatorConfig?.config?.requestedEmulationStatus,
                sensorElement: modulatorConfig?.config?.sensorElement,
            });

            setConfigId(modulatorConfig.config?.id);
        }
    }, [modulatorConfig, form]);

    return (
        <>
            <div
                className="flex flex-wrap lg:flex-nowrap justify-between items-start"
                style={{ padding: '10px' }}
            >
                {/* <div className="flex-grow-mr-4"> */}
                <div className="form-container" style={{ flex: '1 1 50%' }}>
                    <Form
                        onFinish={handleFormFinish}
                        form={form}
                        layout="vertical"
                    >
                        {devices && devices.length > 0 && (
                            <Form.Item
                                name="deviceSerialNumber"
                                rules={[
                                    {
                                        required: true,
                                        message: t(
                                            'Please select device serial number',
                                        ),
                                    },
                                ]}
                                initialValue={
                                    modulatorConfig
                                        ? modulatorConfig.config
                                              ?.deviceSerialNumber
                                        : null
                                }
                            >
                                <Select
                                    placeholder="Gateway Serial"
                                    style={{ width: '100%' }}
                                    onChange={(value: string) =>
                                        console.log(value)
                                    }
                                >
                                    {devices.map(
                                        (device) =>
                                            device.id > 0 && (
                                                <Select.Option
                                                    key={device.id}
                                                    value={device.serialNumber}
                                                >
                                                    {device.serialNumber}
                                                </Select.Option>
                                            ),
                                    )}
                                </Select>
                            </Form.Item>
                        )}
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: t(
                                        'Please specify the Emulation Status',
                                    ),
                                },
                            ]}
                            name="requestedEmulationStatus"
                            initialValue={
                                modulatorConfig
                                    ? modulatorConfig.config
                                          ?.requestedEmulationStatus
                                    : null
                            }
                        >
                            <Select
                                placeholder="Select Emulation Status"
                                style={{ width: '100%' }}
                                onChange={(value: string) => console.log(value)}
                            >
                                <Select.Option value="EMULATION_ON">
                                    EMULATION_ON
                                </Select.Option>
                                <Select.Option value="EMULATION_OFF">
                                    EMULATION_OFF
                                </Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: t('Please input sensor Element'),
                                },
                            ]}
                            name="sensorElement"
                            initialValue={
                                modulatorConfig
                                    ? modulatorConfig.config?.sensorElement
                                    : null
                            }
                        >
                            <Select
                                placeholder="Select Sensor Element"
                                style={{ width: '100%' }}
                                onChange={(value: string) => console.log(value)}
                            >
                                <Select.Option value="PT100">
                                    PT100
                                </Select.Option>
                                <Select.Option value="PT1000">
                                    PT1000
                                </Select.Option>
                                <Select.Option value="NI1000">
                                    NI1000
                                </Select.Option>
                                <Select.Option value="NTC1K8">
                                    NTC1K8
                                </Select.Option>
                                <Select.Option value="NTC10K">
                                    NTC10K
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    </Form>
                </div>

                <div
                    className="table-container"
                    style={{ flex: '1 1 50%', marginLeft: '10px' }}
                >
                    <ModulatorConfigTable
                        ref={tableRef}
                        onCreateConfig={(data) => handleCreateConfig(data)}
                        onEditConfig={(data) => handleEditConfig(data)}
                    />
                </div>

                {/* </div> */}
            </div>
            <div
                className="flex flex-col items-center"
                style={{ padding: '10px' }}
            >
                <div className="mt-4">
                    <Button
                        type="primary"
                        htmlType="submit"
                        onClick={() => {
                            form.submit();
                        }}
                    >
                        {t('Save')}
                    </Button>
                </div>
            </div>
            <div className="flex flex-col items-center">
                <p className="text-red-500 mt-2">{t('Warning gateway')}</p>
            </div>
        </>
    );
}

export default ModulatorConfigView;
