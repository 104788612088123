import { Button, Form, Select, Steps, message } from 'antd';
import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { API } from 'src/api/API';
import Nav from 'src/utils/nav/Nav';
import { useObject } from 'src/hooks/objects/useObject';
import { useTranslation } from 'react-i18next';
import { useDevicesSerialNumbers } from 'src/hooks/objects/useDevicesSerialNumber';
import ModbusTableComponent from './ModbusEditableTable';
import { useNavigate } from 'react-router-dom';
import { useAddIntegration } from 'src/hooks/integrations/useAddIntegration';
import { useUpdateIntegration } from 'src/hooks/integrations/useUpdateIntegration';
import {
    setMasterModbusProperties,
    setMasterModbusData,
} from 'src/store/reducers/masterModbusSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectModbus } from 'src/store/reducers/masterModbusSlice';
import { useOrganizeModbusOnIntegration } from 'src/hooks/integrations/useOrganizeModbusIntegration';

interface Props {
    objectId: number;
    integratorId: number;
    integrationId?: number;
}

function IntegratorModbusView(props: Props) {
    const { objectId, integratorId, integrationId } = props;

    const { t } = useTranslation();
    const [addIntegration] = useAddIntegration();
    const [updateIntegration] = useUpdateIntegration();
    const [organize] = useOrganizeModbusOnIntegration();

    const [selectedDevice, setSelectedDevice] = useState<string>('');
    const [selectedParity, setSelectedParity] = useState<string>('');
    const [selectedStartBits, setSelectedStartBits] = useState<string>('');
    const [selectedBaudRate, setSelectedBaudRate] = useState<string>('');
    const { data: devices } = useDevicesSerialNumbers(objectId);
    const { data: object } = useObject(objectId);
    const history = useNavigate();
    const dispatch = useDispatch();

    const [formData, setFormData] = useState<any>({});
    const [currentStep, setCurrentStep] = useState<number>(0);

    const modbus = useSelector(selectModbus) || null;

    const [form] = Form.useForm();

    console.log(selectedDevice);
    console.log(selectedParity);
    console.log(selectedStartBits);
    console.log(selectedBaudRate);

    const handleFormFinish = (values: any) => {
        setFormData(values);
        setCurrentStep(currentStep + 1);
    };

    const handlePreviousStep = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    };

    const handleCreateIntegration = async (data: any) => {
        const result = await addIntegration(objectId, integratorId, formData);
        const integrationId = result['integrationId'];
        const organizeResult = await organize(objectId, integrationId, data);
        if (organizeResult && organizeResult.length) {
            message.success('Integration created successfully!');
        }
    };

    const handleEditIntegration = async (data: any) => {
        if (integrationId) {
            const updatedFormData = { ...formData };
            updatedFormData.integrationId = integrationId;
            updatedFormData.integratorId = integratorId;
            dispatch(setMasterModbusData(data));
            updateIntegration(objectId, integrationId, updatedFormData);
            const organizeResult = await organize(
                objectId,
                integrationId,
                data,
            );
            if (organizeResult && organizeResult.length) {
                message.success('Integration edited successfully!');
                if (object) {
                    history(
                        Nav.build({
                            path: '/manage-object',
                            queryParams: {
                                index: '1',
                                objectId: object.id.toString(),
                                siteId: object.siteId.toString(),
                                siteName: object.siteName,
                            },
                        }),
                    );
                }
            }
        }
    };

    useEffect(() => {
        if (modbus) {
            form.setFieldsValue({
                gatewaySerialNumber: modbus?.master?.DeviceSerialNumber,
                baudrate: modbus?.master?.Baudrate,
                stopbits: modbus?.master?.StopBits,
                parity: modbus?.master?.Parity,
            });
        }
    }, [modbus, form]);

    // useEffect to fetch data when integrationId changes
    useEffect(() => {
        const fetchData = async () => {
            const cus = await Auth.currentSession().catch(() => null);
            if (cus && cus.isValid()) {
                const authorization = cus
                    ? `Bearer ${cus.getAccessToken().getJwtToken()}`
                    : '';

                const headers = {
                    authorization,
                };
                fetch(
                    API.getURL(
                        `/objects/${objectId}/integrations/${integrationId}/list`,
                    ),
                    {
                        headers,
                    },
                ).then(async (v) => {
                    if (!v.ok) {
                        throw new Error((await v.json()).message);
                    }

                    const result = await v.json();
                    if (result?.master) {
                        dispatch(
                            setMasterModbusProperties({
                                id: result?.master?.id,
                                DeviceSerialNumber:
                                    result?.master?.DeviceSerialNumber,
                                Parity: result?.master?.Parity,
                                StopBits: result?.master?.StopBits,
                                Baudrate: result?.master?.Baudrate,
                            }),
                        );
                    }
                    if (result?.dataPoints) {
                        result.dataPoints[0]['key'] = 1;
                        result.dataPoints = result.dataPoints.map(
                            (dataPoint: any, index: number) => {
                                return {
                                    ...dataPoint,
                                    key: index + 1,
                                    registerNumber: dataPoint.Register,
                                    registerType: dataPoint.RegisterType,
                                    registerDataType:
                                        dataPoint.RegisterDatatype,
                                    scaling: dataPoint.scaleFactor,
                                    name: dataPoint.Name,
                                    sensorFunctionType:
                                        dataPoint.SensorFunctionType,
                                };
                            },
                        );
                        dispatch(setMasterModbusData(result?.dataPoints));
                    }
                });
            }
        };

        if (integrationId) {
            fetchData();
        }
    }, [dispatch, objectId, integrationId]);

    const steps = [
        {
            title: 'Modbus Master',
            content: (
                <>
                    {/* Form fields for Step 1 */}
                    <Form
                        onFinish={handleFormFinish}
                        form={form}
                        layout="vertical"
                    >
                        {devices && devices.length > 0 && (
                            <Form.Item
                                name="gatewaySerialNumber"
                                rules={[
                                    {
                                        required: true,
                                        message: t(
                                            'Please select gateway serial number',
                                        ),
                                    },
                                ]}
                                initialValue={
                                    modbus
                                        ? modbus?.master?.DeviceSerialNumber
                                        : null
                                }
                            >
                                <Select
                                    placeholder="Gateway Serial"
                                    style={{ width: '100%' }}
                                    onChange={(value: string) =>
                                        setSelectedDevice(value)
                                    }
                                >
                                    {devices.map(
                                        (device) =>
                                            device.id > 0 && (
                                                <Select.Option
                                                    key={device.id}
                                                    value={device.serialNumber}
                                                >
                                                    {device.serialNumber}
                                                </Select.Option>
                                            ),
                                    )}
                                </Select>
                            </Form.Item>
                        )}
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: t('Please specify the Baud Rate'),
                                },
                            ]}
                            name="baudrate"
                            initialValue={
                                modbus ? modbus.master?.Baudrate : null
                            }
                        >
                            <Select
                                defaultValue={
                                    modbus ? modbus?.master?.Baudrate : null
                                }
                                placeholder="Select Baud Rate"
                                style={{ width: '100%' }}
                                onChange={(value: string) =>
                                    setSelectedBaudRate(value)
                                }
                            >
                                <Select.Option value="150">150</Select.Option>
                                <Select.Option value="300">300</Select.Option>
                                <Select.Option value="600">600</Select.Option>
                                <Select.Option value="1200">1200</Select.Option>
                                <Select.Option value="2400">2400</Select.Option>
                                <Select.Option value="4800">4800</Select.Option>
                                <Select.Option value="9600">9600</Select.Option>
                                <Select.Option value="19200">
                                    19200
                                </Select.Option>
                                <Select.Option value="38400">
                                    38400
                                </Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: t('Please input stop bits'),
                                },
                            ]}
                            name="stopbits"
                            initialValue={
                                modbus ? modbus?.master?.StopBits : null
                            }
                        >
                            <Select
                                placeholder="Select Stop Bits"
                                style={{ width: '100%' }}
                                onChange={(value: string) =>
                                    setSelectedStartBits(value)
                                }
                            >
                                <Select.Option value="1">1</Select.Option>
                                <Select.Option value="2">2</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: t('Please select a parity option'),
                                },
                            ]}
                            name="parity"
                            initialValue={
                                modbus ? modbus?.master?.Parity : null
                            }
                        >
                            <Select
                                placeholder="Select Parity"
                                style={{ width: '100%' }}
                                onChange={(value: string) =>
                                    setSelectedParity(value)
                                }
                            >
                                <Select.Option value="EVEN">EVEN</Select.Option>
                                <Select.Option value="ODD">ODD</Select.Option>
                                <Select.Option value="NONE">NONE</Select.Option>
                            </Select>
                        </Form.Item>

                        <Button type="primary" htmlType="submit">
                            Next
                        </Button>
                    </Form>
                </>
            ),
        },
        {
            title: 'Templates',
            content: (
                <>
                    <ModbusTableComponent
                        integrationId={integrationId}
                        currentStep={currentStep}
                        onPrevious={handlePreviousStep}
                        onIntegration={(data) => handleCreateIntegration(data)}
                        onEditIntegration={(data) =>
                            handleEditIntegration(data)
                        }
                    />
                </>
            ),
        },
    ];

    return (
        <>
            <div className="flex flex-wrap lg:flex-nowrap items-start gap-4 p-8">
                <div className="w-full max-w-full flex-shrink-0">
                    <Steps
                        current={currentStep}
                        style={{ marginBottom: '20px', width: '24rem' }}
                    >
                        {steps.map((step) => (
                            <Steps.Step key={step.title} title={step.title} />
                        ))}
                    </Steps>
                    {steps[currentStep].content}
                </div>
            </div>
        </>
    );
}

export default IntegratorModbusView;
