// selectedRealtimeSensorsSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/store/rootReducer';

type SelectedApartmentsState = number[];

const initialState: SelectedApartmentsState = [];

export const selectedApartmentsSlice = createSlice({
    name: 'selectedApartments',
    initialState,
    reducers: {
        setSelectedApartments: (state, action: PayloadAction<number[]>) => {
            return [...action.payload];
        },

        addToExistingApartment: (state, action: PayloadAction<number>) => {
            const selectedApartmentsSet = new Set(state);
            selectedApartmentsSet.add(action.payload);
            return Array.from(selectedApartmentsSet);
        },

        addToExistingApartments: (state, action: PayloadAction<number[]>) => {
            const selectedApartmentsSet = new Set(state);
            action.payload.forEach((apartment) =>
                selectedApartmentsSet.add(apartment),
            );
            return Array.from(selectedApartmentsSet);
        },

        deleteExistingApartment: (state, action: PayloadAction<number>) => {
            const selectedApartmentsSet = new Set(state);
            selectedApartmentsSet.delete(action.payload);
            return Array.from(selectedApartmentsSet);
        },
    },
});

export const selectApartments = (state: RootState) => state.selectedApartments;

export const {
    setSelectedApartments,
    addToExistingApartment,
    addToExistingApartments,
    deleteExistingApartment,
} = selectedApartmentsSlice.actions;
export default selectedApartmentsSlice.reducer;
