export interface Integration {
    integrationId: number;
    integratorId: number;
    objectId: number;
    integratorName:
        | 'Web Port'
        | 'EcoGuard'
        | 'Modbus master'
        | 'Modbus tcp'
        | 'Desigo'
        | 'Elvaco'
        | 'Regin'
        | 'Opcua'
        | 'ProptechOs';
}

export enum IntegratorType {
    WebPort = 1,
    EcoGuard = 2,
    Regin = 3,
    Elvaco = 4,
    ModbusMaster = 5,
    ModbusTcp = 6,
    Desigo = 7,
    ProptechOs = 8,
    Opcua = 9,
}

export const IntegratorTypeDescriptions: { [key in IntegratorType]: string } = {
    [IntegratorType.WebPort]: 'WebPort',
    [IntegratorType.EcoGuard]: 'EcoGuard',
    [IntegratorType.Regin]: 'Regin',
    [IntegratorType.Elvaco]: 'Elvaco',
    [IntegratorType.ModbusMaster]: 'Modbus RTU',
    [IntegratorType.ModbusTcp]: 'Modbus TCP',
    [IntegratorType.Desigo]: 'Desigo',
    [IntegratorType.ProptechOs]: 'ProptechOS',
    [IntegratorType.Opcua]: 'OPC UA',
};
