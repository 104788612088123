import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setRealtimeRawTemperatures } from 'src/store/reducers/realtimeRawTemperaturesSlice';
import { addRealtimeTemperatures } from 'src/store/reducers/realtimeTemperturesSlice';
import { setRealTimeDate } from 'src/store/reducers/realTimeVisualization';
import { API } from 'src/api/API';

const use3DDataRealtimeTemperatures = () => {
    const dispatch = useDispatch();

    // Local states for loading, error, and data
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [data, setData] = useState<any>(null);

    const fetchData = useCallback(
        async (body: any) => {
            setIsLoading(true);
            setError(null);

            try {
                const response = await new API().fetchTemperaturesRealTimeVisualizationSite(
                    body,
                );

                // Store raw data and interval in Redux
                dispatch(
                    setRealtimeRawTemperatures({
                        key: `site`,
                        data: response['raw_data'] || [],
                    }),
                );

                dispatch(addRealtimeTemperatures(response?.data));

                if (response?.raw_data.length > 0) {
                    const transformedData = response?.raw_data;
                    const value =
                        transformedData[transformedData.length - 1].timestamp;
                    dispatch(setRealTimeDate(value));
                }

                // Update local data state
                setData(response?.raw_data);
            } catch (e: any) {
                setError(e?.message || 'Failed to fetch data');
            } finally {
                setIsLoading(false);
            }
        },
        [dispatch],
    );

    return { data, isLoading, error, fetchData };
};

export default use3DDataRealtimeTemperatures;
