import React from 'react';
import { Tag } from 'antd';
import { selectApartments } from 'src/store/reducers/selectedApartmentsSlice';
import { setSelectedApartments } from 'src/store/reducers/selectedApartmentsSlice';
import { useDispatch, useSelector } from 'react-redux';

interface HeaderWithApartmentProps {
    realtimeRawTemperatures: any[];
}

function HeaderWithApartmentTags(props: HeaderWithApartmentProps) {
    const { realtimeRawTemperatures } = props;
    const dispatch = useDispatch();

    const handleClose = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        e.stopPropagation();
        dispatch(setSelectedApartments([]));
    };

    const selectedApartments: any = useSelector(selectApartments);

    const getLastNameSensor = () => {
        if (realtimeRawTemperatures && realtimeRawTemperatures.length) {
            const latestId = selectedApartments[selectApartments.length - 1];
            const datapoint = realtimeRawTemperatures.find(
                (realtimeTemp) => realtimeTemp.id === latestId,
            );
            return datapoint?.name || '';
        }

        return '';
    };

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                paddingBottom: 0,
            }}
        >
            <div style={{ fontSize: '20px' }}>Fastighetslogg</div>
            {selectedApartments &&
                !!selectedApartments.length &&
                realtimeRawTemperatures &&
                !!realtimeRawTemperatures.length && (
                    <Tag
                        closable
                        onClose={handleClose}
                        onClick={(
                            e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
                        ) => e.stopPropagation()}
                        style={{
                            cursor: 'pointer',
                            fontSize: '16px',
                            marginLeft: '10px',
                        }}
                    >
                        {getLastNameSensor()}
                    </Tag>
                )}
        </div>
    );
}

export default HeaderWithApartmentTags;
