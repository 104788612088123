import { Form, Input, Button, Row, Col, Typography, message } from 'antd';
import { useTranslation } from 'react-i18next';
// import { useState } from 'react';
import { showError } from 'src/utils/showError';
import Topbar from '../generic/Topbar';
import Nav from 'src/utils/nav/Nav';
import { useNavigate } from 'react-router-dom';
import { CreateDeviceDTO } from 'src/models/Device';
import { useAddDevice } from 'src/hooks/objects/useAddDevice';

type Props = {
    objectId: number;
    siteId: number;
    siteName: string;
    onSuccess(): void;
};
function AddDeviceView(props: Props) {
    const [form] = Form.useForm();
    const { objectId, onSuccess } = props;
    const { t } = useTranslation();
    // const [scanActive, setScanActive] = useState(false);
    const [add] = useAddDevice();
    const history = useNavigate();

    const handleOnSubmit = (v: any) => {
        const tempDevice: CreateDeviceDTO = {
            serialNumber: v.id,
            name: v.name,
        };
        add(objectId, tempDevice)
            .then((object) => {
                message.success(t('Succesfully created new device!'));
                onSuccess();
            })
            .catch((er) => {
                showError(t('Not able to add new device'), er.message);
            });
    };

    return (
        <>
            <div className="relative">
                <div className="w-screen p-10 text-center h-screen">
                    <Typography.Title level={4}>
                        {t('Create device')}
                    </Typography.Title>
                    <Row align="middle" justify="center" gutter={[20, 20]}>
                        <Col md={12} xs={24}>
                            <Form
                                form={form}
                                layout="vertical"
                                onFinish={handleOnSubmit}
                            >
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: t('Device id is required'),
                                            validator(_, value: string) {
                                                if (
                                                    value.length === 12 &&
                                                    Number.isInteger(
                                                        Number(value),
                                                    )
                                                ) {
                                                    return Promise.resolve();
                                                }

                                                return Promise.reject(
                                                    new Error(
                                                        'Something went wrong, try again!',
                                                    ),
                                                );
                                            },
                                        },
                                    ]}
                                    label={t('Device id')}
                                    name="id"
                                >
                                    <Input placeholder="010331040002" />
                                </Form.Item>
                                <Form.Item label={t('Name')} name="name">
                                    <Input placeholder="" />
                                </Form.Item>
                                {/* <div id="scan-button">
                                        <Button
                                            block
                                            size="large"
                                            onClick={() => setScanActive(true)}
                                        >
                                            {t('Scan device id')}
                                        </Button>
                                    </div> */}
                                <Button
                                    className="mt-3"
                                    block
                                    size="large"
                                    type="primary"
                                    htmlType="submit"
                                >
                                    {t('Add device')}
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </div>
                <div className="fixed bottom-0 w-screen">
                    <div className="w-full mb-5 flex" id="go-back-button">
                        <Button
                            onClick={() => {
                                history(
                                    Nav.build({
                                        path: '/manage-object',
                                        queryParams: {
                                            index: '1',
                                            objectId: `${objectId}`,
                                            siteId: `${props.siteId}`,
                                            siteName: `${props.siteName}`,
                                        },
                                    }),
                                );
                            }}
                            type="primary"
                            className="m-auto"
                        >
                            {t('Go back')}
                        </Button>
                    </div>
                    <Topbar active="sites" />
                </div>
            </div>
        </>
    );
}

export default AddDeviceView;
