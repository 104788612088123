import React, {
    useState,
    useEffect,
    forwardRef,
    useImperativeHandle,
} from 'react';
import { Table, Input, Button } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { selectConfig } from 'src/store/reducers/modulatorConfigSlice';
import { useSelector } from 'react-redux';

export interface TableRow {
    offsetId: number;
    key: string;
    //controlState: number;
    out: number;
    offsetValue: number;
}

interface TableProps {
    onCreateConfig: (data: any) => void;
    onEditConfig: (data: any) => void;
}

const ModulatorConfigTable = forwardRef((props: TableProps, ref) => {
    const modulatorConfig = useSelector(selectConfig) || null;

    const [data, setData] = useState<TableRow[]>([]);
    const [newRow, setNewRow] = useState<TableRow>({
        offsetId: 0,
        key: String(Date.now()),
        out: 0,
        //controlState: 0,
        offsetValue: 0,
    });
    const generateKey = (): string => String(data.length + 1);

    const columns: ColumnProps<TableRow>[] = [
        // {
        //     title: 'Control State',
        //     dataIndex: 'controlState',
        //     key: 'controlState',
        //     render: (text, record) =>
        //         renderEditableCell(text, record.key, 'controlState'),
        // },
        {
            title: 'Out',
            dataIndex: 'out',
            key: 'out',
            render: (text, record) =>
                renderEditableCell(text, record.key, 'out'),
        },
        {
            title: 'OffsetValue',
            dataIndex: 'offsetValue',
            key: 'offsetValue',
            render: (text, record) =>
                renderEditableCell(text, record.key, 'offsetValue'),
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Button onClick={() => handleDeleteRow(record.key)}>
                    Delete
                </Button>
            ),
        },
    ];

    const handleChange = (
        key: keyof TableRow,
        value: string | number,
        recordKey: string,
    ) => {
        setData((prevData) =>
            prevData.map((row) =>
                row.key === recordKey ? { ...row, [key]: value } : row,
            ),
        );
    };

    const handleAddRow = () => {
        const newRowWithKey: TableRow = {
            ...newRow,
            key: String(data.length + 1),
        };
        setData([...data, newRowWithKey]);
        setNewRow({
            offsetId: 0,
            key: generateKey(),
            out: 0,
            //controlState: 0,
            offsetValue: 0,
        });
    };

    const handleDeleteRow = (recordKey: string) => {
        setData((prevData) => prevData.filter((row) => row.key !== recordKey));
    };

    const renderEditableCell = (
        text: string,
        recordKey: string,
        dataIndex: keyof TableRow,
    ) => {
        return (
            <Input
                value={data.find((row) => row.key === recordKey)?.[dataIndex]}
                onChange={(e) =>
                    handleChange(dataIndex, e.target.value, recordKey)
                }
            />
        );
    };

    useEffect(() => {
        if (modulatorConfig && modulatorConfig.config) {
            setData(modulatorConfig.data);
        }
    }, [modulatorConfig]);

    useImperativeHandle(ref, () => ({
        getData: () => data,
    }));

    return (
        <div>
            <Table dataSource={data} columns={columns} />
            <Button onClick={handleAddRow}>Add</Button>
        </div>
    );
});

export default ModulatorConfigTable;
